import React, {useContext} from 'react';
import Link from '@mui/material/Link';
import { GlobalContext } from '../../contexts/global';
import { getContactUsLink } from '../../util/helpers';

type NeedHelpProps = { theme: string }

const NeedHelpComponent: React.FC<NeedHelpProps> = (props: NeedHelpProps) => {
  const { globalContext, setGlobalContext} = useContext(GlobalContext);
  const { theme } = props;

  return (
    <div className="need-help">
      {theme === 'choices' ? 'Have Questions?' : 'Need help?'}&nbsp;
      <Link
        href={getContactUsLink( globalContext.referer )}
        target="_blank"
        className="need-help-link link-tamewhite-contact-us-link link-choices"
      >
        Contact us
      </Link>
    </div>
  );
};

export default NeedHelpComponent;