// generates addresses dynamically. can add a delete button in here in phase 2 when needed
import {OAuth2Tokens} from "@forgerock/javascript-sdk";
import Link from "@mui/material/Link";
import React from "react";
import Typography from "@mui/material/Typography";
import {NavigateFunction} from "react-router";
import {GlobalContextType} from "../../contexts/types/global";
import {Checkboxes, MyAccountType, SingleAddress} from "../../contexts/types/myAccount";
import {validateiPlanetSession} from "../../network-requests/authentication";
import {getContextUserInfo, updateUsersEmailSubscriptions, verifyMergedAccount} from "../../network-requests/resources";
import {
  LINK_CHOICES,
  LINK_MEINHARDT,
  LINK_MR,
  LINK_PSF,
  LINK_SOF,
  LINK_UF,
  RELAY_STATE_REACT_APP_CHOICES,
  RELAY_STATE_REACT_APP_MEINHARDT,
  RELAY_STATE_REACT_APP_MR,
  RELAY_STATE_REACT_APP_PSF,
  RELAY_STATE_REACT_APP_SOF,
  RELAY_STATE_REACT_APP_UF
} from "../../util/constants";
import { getContactUsLink } from '../../util/helpers';
import {formatPhoneNumber} from "../../util/phoneFormatter";
import {AddressBookDivs, OrganizedAddressBook} from "../my-account/addresses/addressesTypes";
import {organizeAddressBook} from "../my-account/addresses/generated-addresses/generateAddresses";
import {CheckboxState} from "../my-account/email-subscriptions/emailSubscriptionsTypes";
import {checkboxOptions} from "../my-account/email-subscriptions/generate-checkbox-divs/checkboxOptions";
import {FormControl, Grid, ToggleButton, ToggleButtonGroup} from "@mui/material";
import {ensureAnyCheckboxesAreChecked} from "../my-account/email-subscriptions/helper-functions/helpers";

const themeConverter = async (theme: string) => {
  switch (theme) {
    case 'saveonfoods':
      return 'saveOnFoodsFlyerSpecials';
    case 'morerewards':
      return 'moreRewardsProgram';
    case 'urbanfare':
      return 'urbanFareSpecials';
    case 'pricesmartfoods':
      return 'priceSmartSpecials';
  }
}

const handleEmailSubChange = async (
  checkboxState: CheckboxState,
  setCheckboxState: React.Dispatch<React.SetStateAction<CheckboxState>>,
  checkboxItemToChange: string,
  buttonValue: string
) => {
  if (buttonValue === null) {
    return;
  }
  if (buttonValue) {
    setCheckboxState((prev: CheckboxState) => ({
      ...prev,
      initialCheckboxesState: {
        ...prev.initialCheckboxesState,
        [checkboxItemToChange]: true
      },
    }));
  } else {
    setCheckboxState((prev: CheckboxState) => ({
      ...prev,
      initialCheckboxesState: {
        ...prev.initialCheckboxesState,
        [checkboxItemToChange]: false
      },
    }));
  }
}

const organizeAddressDivs = async (
  accountData: MyAccountType,
  organizedAddressDivs: AddressBookDivs,
  type: string,
  addressesToAdd: SingleAddress[],
) => {
  let organizedAddressDivType: JSX.Element[];
  switch (type) {
    case 'billingAddresses':
      organizedAddressDivType = organizedAddressDivs.billingAddressesDivs;
      break;
    case 'shippingAddress':
      organizedAddressDivType = organizedAddressDivs.shippingAddressDivs;
      break;
    case 'billingShippingAddress':
      organizedAddressDivType = organizedAddressDivs.billingShippingAddressDivs;
      break;
    case 'alternateAddress':
      organizedAddressDivType = organizedAddressDivs.alternateAddressDivs;
      break;
  }

  addressesToAdd.forEach((addressToAdd: SingleAddress) => {
    organizedAddressDivType.push(
      <Grid item xs={12} sm={12} md={6} lg={6} className="addressWrapper">
        <div className="addressContainer">
          <div className="addressInner">
            {type === 'billingAddresses' &&
              <Typography sx={{ fontSize: 18, fontWeight: 'bold', marginBottom: '10px' }}>
                Billing
              </Typography>
            }
            {type === 'shippingAddress' &&
              <Typography sx={{ fontSize: 18, fontWeight: 'bold', marginBottom: '10px' }}>
                Shipping
              </Typography>
            }
            {type === 'billingShippingAddress' &&
              <Typography sx={{ fontSize: 18, fontWeight: 'bold', marginBottom: '10px' }}>
                Billing and Shipping
              </Typography>
            }
            <div className="nameContainer">
              {addressToAdd.firstName ?
                <Typography className="firstNameMargin">
                  {addressToAdd.firstName}
                </Typography>
                :
                <></>
              }
              {addressToAdd.lastName ?
                <Typography>
                  {addressToAdd.lastName}
                </Typography>
                :
                <></>
              }
            </div>
            {addressToAdd.primaryPhone ?
              <Typography className="generatedAddressTypography">
                {formatPhoneNumber(addressToAdd.primaryPhone)}
              </Typography>
              :
              <></>
            }
            <Typography className="generatedAddressTypography">
              {addressToAdd.street1} {addressToAdd.street2}
            </Typography>
            <Typography className="generatedAddressTypography">
              {addressToAdd.cityName} {addressToAdd.provinceCode} {addressToAdd.postalCode}
            </Typography>
            <Typography className="generatedAddressTypography">
              {addressToAdd.countryCode}
            </Typography>
          </div>
        </div>
      </Grid>
    )
  });
}

const generateAddresses = async (
  accountData: MyAccountType,
  setAddressBookDivs: React.Dispatch<React.SetStateAction<AddressBookDivs>>
): Promise<void> => {
  const organizedAddressBook: OrganizedAddressBook = await organizeAddressBook(accountData.addresses);
  const organizedAddressDivs: AddressBookDivs = {
    billingAddressesDivs: [],
    shippingAddressDivs: [],
    billingShippingAddressDivs: [],
    alternateAddressDivs: []
  }
  for (const [key, value] of Object.entries(organizedAddressBook)) {
    await organizeAddressDivs(
      accountData,
      organizedAddressDivs,
      key,
      value
    )
  }
  setAddressBookDivs(organizedAddressDivs);
};

const generateEmailSubsDivs = async (
  setEmailSubDivs: React.Dispatch<React.SetStateAction<JSX.Element[]>>,
  handleCheckboxChange: Function,
  checkboxState: CheckboxState,
  accountData: MyAccountType,
  theme: string,
  setCheckboxState: React.Dispatch<React.SetStateAction<CheckboxState>>
): Promise<void> => {
  const convertedTheme: string = await themeConverter(theme);
  const arrayOfCheckBoxDivs: JSX.Element[] = [];
  for (const [accountDataKey, value] of Object.entries(accountData.emailSubscriptions)) {
    checkboxOptions.forEach(checkboxInfo => {
      if (accountDataKey === checkboxInfo.keyDescription) {
        Object.entries(checkboxState.initialCheckboxesState).map(([key, valueCheckboxState]) => {
          if (
            key === checkboxInfo.keyDescription &&
            (convertedTheme === key || key === 'moreRewardsProgram') &&
            !value
          ) {
            arrayOfCheckBoxDivs.push(
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="flex-container-welcome">
                  <Typography className='BrandsubWelcome'>
                    {checkboxInfo.title}
                    <Typography className="sub-text"></Typography>
                  </Typography>
                  <Typography className="BrandsuboptionList WelcomesuboptionList">
                    {checkboxInfo.youWillRecieve.first}
                  </Typography>
                  <Grid item xs={12} sm={12} md={4} lg={4} className="welcomeMessageEmailSubsBoxes">
                    <FormControl error variant="standard">
                      <ToggleButtonGroup
                        className="YesNoWelcomeMessage"
                        value={valueCheckboxState}
                        exclusive
                        onChange={(event: React.MouseEvent<HTMLElement, MouseEvent>, buttonValue) => {
                          handleEmailSubChange(checkboxState, setCheckboxState, key, buttonValue);
                        }}>
                        <ToggleButton value={true}>{'Yes Please'}</ToggleButton>
                        <ToggleButton value={false}>{'No Thanks'}</ToggleButton>
                      </ToggleButtonGroup>
                    </FormControl>
                  </Grid>
                </div>
              </Grid>
            );
          }
        })
      }
    });
  }
  setEmailSubDivs(arrayOfCheckBoxDivs);
};

const redirectUsersWindow = (theme: string) => {
  switch (theme) {
    case 'choices':
      return window.location.replace(`${LINK_CHOICES}`);
    case 'meinhardt':
      return window.location.replace(`${LINK_MEINHARDT}`);
    case 'saveonfoods':
      return window.location.replace(`${LINK_SOF}`);
    case 'pricesmartfoods':
      return window.location.replace(`${LINK_PSF}`);
    case 'urbanfare':
      return window.location.replace(`${LINK_UF}`);
    default:
      return window.location.replace(`${LINK_MR}`)
  }
}

/* This function opens another instance of the React app in a new tab with the
 * correct theming, without Chrome blocking it. Also lands on the users Profile Pages */
const openLinkInNewTab = (url: string) => {
  const link: HTMLAnchorElement = document.createElement('a');
  link.href = url;
  link.target = '_blank';
  link.rel = 'noopener noreferrer';
  link.click();
};

const continueWithLogin = async (
  checkboxState: CheckboxState,
  theme: string,
  goto: string,
  enqueueSnackbar: any,
  setLoadingAPI: React.Dispatch<React.SetStateAction<boolean>>,
  accountData: MyAccountType,
  updateProfileNow: boolean
) => {
  try {
    await setLoadingAPI(true);
    // Update email subs only if a change was made
    if (ensureAnyCheckboxesAreChecked(checkboxState.initialCheckboxesState)) {
      await updateUsersEmailSubscriptions(await convertNullSubsToFalse(
        checkboxState.initialCheckboxesState,
        accountData)
      );
    }
    await verifyMergedAccount(); // Flip hasPostMerged on profile from 'prompt_screen' to 'unconfirmed'
    if (updateProfileNow) {
      // Open the React App's profile pages in a New Tab while continuing on with the SSO login in first tab
      switch(theme) {
        case 'saveonfoods':
          openLinkInNewTab(RELAY_STATE_REACT_APP_SOF) // Open with Save-On-Foods Theme
          break;
        case 'urbanfare':
          openLinkInNewTab(RELAY_STATE_REACT_APP_UF); // Open with Urban Fare Theme
          break;
        case 'pricesmartfoods':
          openLinkInNewTab(RELAY_STATE_REACT_APP_PSF); // Open with PriceSmart Theme
          break;
        case 'choices':
          openLinkInNewTab(RELAY_STATE_REACT_APP_CHOICES); // Open with Choices Markets Theme
          break;
        case 'meinhardt':
          openLinkInNewTab(RELAY_STATE_REACT_APP_MEINHARDT); // Open with Meinhardt Fine Foods Theme
          break;
        default:
          openLinkInNewTab(RELAY_STATE_REACT_APP_MR); // Open with More Rewards Default theme
      }
    }
    if (goto) return window.location.replace(goto); // Continue single sign on back to Banner
    redirectUsersWindow(theme); // Otherwise continue to banner login
    return setLoadingAPI(false);
  } catch (e) {
    console.error(e);
    setLoadingAPI(false);
    enqueueSnackbar(
      <>
        <Typography
          sx={{ marginRight: '5px', marginLeft: '5px', cursor: 'pointer' }}>
          An unexpected error occurred submitting your changes. Please try again. If this issue persists please&nbsp;
          <Link
            href={getContactUsLink(theme)}
            target="_blank"
            className="link-white link-choices"
          >contact us
          </Link>
        </Typography>
      </>,
      { variant: 'error' }
    );
  }
}

const fetchProfile = async (
  globalContext: GlobalContextType,
  setGlobalContext: React.Dispatch<React.SetStateAction<GlobalContextType>>,
  navigate: NavigateFunction,
  isAuth: OAuth2Tokens,
  setIsAuth: React.Dispatch<React.SetStateAction<OAuth2Tokens>>,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setError: React.Dispatch<React.SetStateAction<string>>,
  setErrorLogin: React.Dispatch<React.SetStateAction<string>>,
  setAccountData: React.Dispatch<React.SetStateAction<MyAccountType>>,
  enqueueSnackbar: any,
  goto: string
) => {
  setLoading(true)
  const sessionValid = await validateiPlanetSession(
    setGlobalContext,
    navigate,
    globalContext,
    setIsAuth,
    isAuth,
    setErrorLogin,
    setLoading,
    enqueueSnackbar,
    goto
  )
  if (sessionValid) {
    let data: MyAccountType | void = await getContextUserInfo(setError);
    if (data) {
      const validJsonData: MyAccountType = data;
      await setAccountData(validJsonData);
      return setLoading(false);
    } else { // If no valid data from API
      if (goto) return window.location.replace(goto);
      navigate('/userPage/home');
    }
    setLoading(false);
    return setError(`There was an issue with fetching your profile data. Please try again.`);
  }
}

const compareAccountDataWithInitialCheckboxesState = async (
  copiedInitialCheckboxesState: Checkboxes,
  accountData: MyAccountType
) => {
  for (const [key, value] of Object.entries(accountData.emailSubscriptions)) {
    if (!value && !copiedInitialCheckboxesState[key]) {
      copiedInitialCheckboxesState[key] = false;
    } else {
      copiedInitialCheckboxesState[key] = true;
    }
  }
  return copiedInitialCheckboxesState;
}

// logic to ensure all checkboxes are unchecked
const convertNullSubsToFalse = async (
  initialCheckboxesState: Checkboxes,
  accountData: MyAccountType
): Promise<Checkboxes> => {
  let copiedInitialCheckboxesState: Checkboxes = await JSON.parse(JSON.stringify(initialCheckboxesState));
  compareAccountDataWithInitialCheckboxesState(copiedInitialCheckboxesState, accountData)
  return copiedInitialCheckboxesState;
};

export {
  generateAddresses,
  generateEmailSubsDivs,
  continueWithLogin,
  fetchProfile,
  convertNullSubsToFalse
}
