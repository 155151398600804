import React from "react";
import {emailValidator} from "../../../util/validator";
import {
  digitalCreateAccount,
  sendVerifyEmail,
  verifyEmailExists
} from "../../../network-requests/register";
import {handleThemeRedirection} from "../../my-account/myAccountHelpers";
import {RegInfoType} from "../../../contexts/registration";
import {DigitalRegisterFields, ValidationStatus} from "../verify-email/digital-verify-email";
import {TFunction, Trans} from "react-i18next";
import {pushSpecificEvent} from "../../../util/google-tag-manager";
import {GlobalContextType} from "../../../contexts/types/global";
import { NavigateFunction } from "react-router-dom";
import {displayUnexpectedError} from "../../../components/notifications/errors/display-unexpected-error";
import {
  displaySendVerificationEmailConfirmation
} from "../../../components/notifications/success/send-email-confirmation-success";
import {displayCustomError} from "../../../components/notifications/errors/display-custom-error";
import {
  DIGITAL_CARD_REGISTRATION_EMAIL_ERROR,
  DIGITAL_CARD_REGISTRATION_EMAIL_TAKEN,
  HTTP_STATUS_OK,
  HTTP_STATUS_TOO_MANY_REQUESTS,
  REDIRECT_TIMEOUT
} from "../../../util/constants";


const handleSubmit = async (
  event: React.FormEvent<HTMLFormElement>,
  email: string,
  setEmailHelperText: React.Dispatch<React.SetStateAction<string>>,
  setRegistrationContext: React.Dispatch<React.SetStateAction<RegInfoType>>,
  enqueueSnackbar: any,
  setEmailIsSent: React.Dispatch<React.SetStateAction<boolean>>,
  t: TFunction,
  theme: string,
  globalContext: GlobalContextType,
  navigate: NavigateFunction
) => {
  event.preventDefault();
  if (emailValidator(email, t).length !== 0) { // check if email is valid if not stop process
    setEmailHelperText(emailValidator(email, t));
    return;
  }
  // disable the submit button
  setEmailIsSent(true);
  sendVerifyEmail(email, theme).then((r: Response) => { // if not successful display error response and stop
    if (r.status !== HTTP_STATUS_OK) {
      if (r.status === HTTP_STATUS_TOO_MANY_REQUESTS) {
        r.text().then((msg: string) => { displayCustomError(enqueueSnackbar, theme, msg) });
        return;
      }
      r.text().then(() => displayUnexpectedError(enqueueSnackbar, theme));
      return;
    }
    setRegistrationContext((prev: RegInfoType) => ({ ...prev, email: email }));
    pushSpecificEvent('Register: VerifyEmailPage - Email Sent');
    displaySendVerificationEmailConfirmation(enqueueSnackbar, theme, email);
    setEmailHelperText('');
    if (globalContext.isWebview) {
      navigate('/'); // If the user logged in on a mobile web view, take them back to the login page preserving the 'goto'
    } else {
      setTimeout(() => handleThemeRedirection(theme, globalContext.trafficType), REDIRECT_TIMEOUT); // Otherwise punt them back to the relevant banner on desktop
    }
  }).catch(() => enqueueSnackbar(t('ERRORS.API_OFFLINE'), {variant: 'error'}));
};

/**
 * This function handles the form submission for digital registration/signup. Here we make a HTTP POST request to
 * ~/register/digital-card in the microservice to create a new account. We then handle the response for bad requests,
 * too many requests, server errors, and successful requests and handle the returned response data.
 * @param event   React.FormEvent<HTMLFormElement> object
 * @param email   a string
 * @param fName   a string
 * @param lName   a string
 * @param setRegistrationContext  a  React.Dispatch<React.SetStateAction<RegInfoType>> object
 * @param registrationContext     a RegInfoType object
 * @param enqueueSnackbar   an object
 * @param t   a TFunction
 * @param theme   a string
 * @param navigate    a NavigationFunction
 * @param setLoading  a boolean
 */
const handleDigitalSubmit = async (
  event: React.FormEvent<HTMLFormElement>,
  email: string,
  fName: string,
  lName: string,
  isEmailSubscribed: boolean,
  setRegistrationContext: React.Dispatch<React.SetStateAction<RegInfoType>>,
  registrationContext: RegInfoType,
  enqueueSnackbar: any,
  t: TFunction,
  theme: string,
  navigate: NavigateFunction,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  event.preventDefault();
  setLoading(true);
  // We set the global registration context to keep track of the already known user information for the session
  setRegistrationContext( (prev: RegInfoType) => ({
    ...prev,
    "email": email,
    "fName": fName,
    "lName": lName,
    "sendEmail": isEmailSubscribed
  }))

  try {
    const response = await digitalCreateAccount(registrationContext, email, fName, lName, isEmailSubscribed);
    if (!response.ok) {
      setLoading(false);
      if (response.status === 500) {
        response.text().then((msg: string) => {
          displayCustomError(enqueueSnackbar, theme, t('REGISTER.DIGITAL_CREATE_ACCOUNT.ERROR.SERVER_ERROR'));
          throw new Error(`Internal server error | status: ${response.status} | msg: ${msg}`);
        })
      }
      if (response.status === 400) {
        response.text().then((msg: string) => {
          displayCustomError(enqueueSnackbar, theme, msg);
          throw new Error(`Bad request | status: ${response.status} | msg: ${msg}`);
        })
      }
      if (response.status === 429) {
        response.text().then((msg: string) => {
          displayCustomError(enqueueSnackbar, theme, t('REGISTER.DIGITAL_CREATE_ACCOUNT.ERROR.TOO_MANY_REQUESTS'));
          throw new Error(`Too many requests | status: ${response.status} | msg: ${msg}`);
        })
      }
    } else {
      setLoading(false);
      const data = await response.json();
      const accountData = {
        "morerewardscard": data.registerDigitalMRCard.mrCardNbr,
        "activationcode": data.registerDigitalMRCard.activationCode,
        "digitalMRJwt": data.registerDigitalMRCard.digiMRJwt
      }
      navigate('/register-digital/complete', { state: accountData });
    }
  } catch (e) {
    console.error(e);
  }
  return;
}

/**
 * This function makes a request to /register/email-available and check in the database if an email exists.
 * If it doesn't exist the email is available and if it does then it's unavailable.
 * @param email
 * @param setValidationStatus
 * @param setDigitalRegisterFields
 * @param t
 */
const handleVerifyEmailExists = async(
  email: string,
  setValidationStatus: React.Dispatch<React.SetStateAction<ValidationStatus>>,
  setDigitalRegisterFields: React.Dispatch<React.SetStateAction<DigitalRegisterFields>>,
  t: TFunction
) => {
  try {
    let response = await verifyEmailExists(email);
    const data = await response.json();
    if (!response.ok || response.status !== 200) {
      // Handle API error
      setValidationStatus({
        isValidating: false,
        available: false,
        error: true,
        isValid: false,
        email: email
      });
      setDigitalRegisterFields((prev: DigitalRegisterFields) => ({
        ...prev,
        emailHelperText: DIGITAL_CARD_REGISTRATION_EMAIL_ERROR
      }));
    } else {
      if (data && typeof data.available === "boolean") {
        setValidationStatus({
          isValidating: false,
          available: data.available,
          error: false,
          isValid: true,
          email: email
        });
        // Set helper text based on email availability
        setDigitalRegisterFields((prev: DigitalRegisterFields) => ({
          ...prev,
          emailHelperText: data.available
            ? '' // Email is available
            : (
              <>
                {DIGITAL_CARD_REGISTRATION_EMAIL_TAKEN}
              </>
            )
        }));
      } else {
        // Invalid data format from API
        setValidationStatus({
          isValidating: false,
          available: false,
          error: true,
          isValid: false,
          email: email
        });
        setDigitalRegisterFields((prev: DigitalRegisterFields) => ({
          ...prev,
          emailHelperText: DIGITAL_CARD_REGISTRATION_EMAIL_ERROR
        }));
      }
    }
  } catch (e) {
    // Handle network or other errors
    setValidationStatus({
      isValidating: false,
      available: false,
      error: true,
      isValid: false,
      email: email
    });
    setDigitalRegisterFields((prev: DigitalRegisterFields) => ({
      ...prev,
      emailHelperText: DIGITAL_CARD_REGISTRATION_EMAIL_ERROR
    }));
    console.error('Error validating email exists: ', e);
  }
};

export {handleSubmit, handleDigitalSubmit, handleVerifyEmailExists}