import {Link, Typography} from "@mui/material";
import React from 'react';
import { getContactUsLink } from '../../../../util/helpers';
import {EmailInput} from '../changeEmailTypes';
import {sendChangeCustomerEmail} from '../../../../network-requests/resources';
import {ProfileInfoType} from '../../../../contexts/types/myAccount';
import {GlobalContextType} from '../../../../contexts/types/global';
import {NavigateFunction} from 'react-router';

const changeUsersEmail = async (
  email: EmailInput,
  theme: string
): Promise<any> => {
  return await sendChangeCustomerEmail(email, theme);
};

// TODO - add network request logic here and move this code into a network request directory
const handleSubmitEmailChange = async (
  setNewEmail: React.Dispatch<React.SetStateAction<EmailInput>>,
  email: EmailInput,
  setEmailHelperText: React.Dispatch<React.SetStateAction<string>>,
  setEmailConfirmationHelperText: React.Dispatch<React.SetStateAction<string>>,
  profileInfo: ProfileInfoType,
  updateProfileInfo: any,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setRehydrateApplication: React.Dispatch<React.SetStateAction<boolean>>,
  t: any,
  navigate: NavigateFunction,
  setGlobalContext: React.Dispatch<React.SetStateAction<GlobalContextType>>,
  enqueueSnackbar: any,
  theme: string
): Promise<void> => {
  setLoading(true);
  try {
    const resultJSON: any = await changeUsersEmail(email, theme);
    if (resultJSON.data?.beginMRVerifyEmail.success) {
      enqueueSnackbar(
        <div className="flexMessage">
        <Typography sx={{marginRight: '10px', cursor: 'pointer'}}>
          We have received your email change request successfully!&nbsp;<Link className="link-white"></Link>
        </Typography>
        <Typography sx={{marginTop: '10px'}}>
          We sent you an email to confirm your new address. Please click on the link we sent you in order for the changes to take effect.
        </Typography>
        <Typography sx={{marginTop: '10px'}}>
          If you don’t get our message, please check your Junk folder and remember to add <strong>no-reply@morerewards.ca</strong> to your address books.&nbsp;
          <Link
            href={getContactUsLink(theme)}
            target="_blank"
            className="link-white"
          >contact us
          </Link>.
        </Typography>
      </div>, { variant: 'success' });
      await setNewEmail((prev: EmailInput) => ({
        ...prev,
        newEmail: '',
        newEmailConfirmation: ''
      }));
    } else {
      const message = resultJSON.error || 'Uh oh, something went wrong...';
      enqueueSnackbar(message, { variant: 'error' });
    }
  } finally {
    setLoading(false);
  }
};

const checkMatchingInput = (
  emailHelperText: string,
  emailConfirmationHelperText: string,
  setEmailConfirmationHelperText: React.Dispatch<React.SetStateAction<string>>,
  setEmailHelperText: React.Dispatch<React.SetStateAction<string>>,
  email: EmailInput,
  profileInfo: ProfileInfoType,
): void => {
  const emailNoMatch: string = `Emails don’t match`;
  const emailEnteredIncorrect: string = `Email appears to be incorrectly entered`;
  const oldAndNewEmailCannotMatch: string = 'Your current email and new email cannot be the same!';
  if (profileInfo.email.toLowerCase() === email.newEmail) {
    return setEmailHelperText(oldAndNewEmailCannotMatch)
  }
  if ((email.newEmail !== email.newEmailConfirmation) &&
    (email.newEmailConfirmation !== '' && email.newEmail !== '') &&
    (emailHelperText !== emailEnteredIncorrect && emailConfirmationHelperText !== emailEnteredIncorrect)
  ) {
    setEmailConfirmationHelperText(emailNoMatch);
  } else {
    if (emailHelperText !== emailEnteredIncorrect) {
      setEmailHelperText('');
    }
    if (emailConfirmationHelperText !== emailEnteredIncorrect) {
      setEmailConfirmationHelperText('');
    }
  }
};

export {handleSubmitEmailChange, checkMatchingInput};