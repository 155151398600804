import React, {useContext, useEffect, useMemo, useState} from 'react';
import {Route, Routes, useSearchParams} from 'react-router-dom';
import {RegisterPageRouterProps} from "../../App";
import {GlobalContextType} from "../../contexts/types/global";
import VerifyEmailPage from './verify-email/1-verify-email';
import CreateAccountPage from './create-account/2-create-account';
import SecurityQuestionsPage from './security-questions/3-security-questions';
import ContactCustomerServicePage from './contact-customer-service/contact-customer-service';
import ValidationPage, {RegisterFields} from './validation/4-validation';
import AddressPage from './addresses/5-address';
import CompleteRegistrationSuccess from './complete-registration-success/6-complete-registration-success';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent
} from '@mui/material';
import {RegInfoType, RegistrationContext, RegistrationPagesEnum} from '../../contexts/registration';
import {GlobalContext} from '../../contexts/global';
import {IS_PRODUCTION} from '../../util/constants';
import {PhoneNumbers} from '../my-account/profile/profile';
import {PhoneErrors} from "../my-account/myAccountTypes";
import {AddressValidationFields} from "./addresses/addresses-types";
import './register-router.scss';

/**
 * Used to route register pages
 * @constructor
 */
function RegistrationPageRouter(props: RegisterPageRouterProps) {
  const { theme } = props;
  const [searchParams] = useSearchParams();
  const { setGlobalContext } = useContext(GlobalContext);
  const { registrationContext, setRegistrationContext } = useContext(RegistrationContext);

  // edit address state
  const [addressFields, setAddressFields] = useState<AddressValidationFields>({
    addressLine1: registrationContext.addressLine1,
    addressLine1HelperText: '',
    addressLine2: registrationContext.addressLine2,
    addressLine2HelperText: '',
    city: registrationContext.city,
    cityHelperText: '',
    postalCode: '',
    postalCodeHelperText: '',
    country: 'CA',
    province: registrationContext.province,
    provinceHelperText: '',
    isAgreeTOS: false,
    isAgreeTOSHelperText: '',
    submitting: false
  });

  // register/validate profile state
  const [registerFields, setRegisterFields] = useState<RegisterFields>({
    password: '',
    passwordHelperText: '',
    confirmPassword: '',
    confirmPasswordHelperText: '',
    fName: '',
    fNameHelperText: '',
    lName: '',
    lNameHelperText: '',
    receiveEmails: null,
    receiveEmailsHelperText: '',
    dobYear: registrationContext.dob.split('-')[0],
    dobMonth: registrationContext.dob.split('-')[1],
    dobDay: registrationContext.dob.split('-')[2],
    dobHelperText: '',
    showPass: ''
  });
  const [phoneEditState, setPhoneEditState] = useState<PhoneNumbers>({
    primary: '',
    alternate1: ''
  });
  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState<PhoneNumbers>({
    primary: '',
    alternate1: ''
  });
  const [phoneEditErrors, setPhoneEditErrors] = useState<PhoneErrors>({
    primaryIncorrectlyEntered: {
      value: null,
      disableSaveButton: false
    },
    alternate1PhoneIncorrectlyEntered: {
      value: null,
      disableSaveButton: false
    },
    alternate2PhoneIncorrectlyEntered: {
      value: null,
      disableSaveButton: false
    },
    primaryTooShort: {
      value: null,
      disableSaveButton: false
    },
    primaryEmpty: {
      value: null,
      disableSaveButton: false
    }
  });
  let jwt = useMemo(() => searchParams.get('jwt'), []);
  useEffect(() => {
    if (!registrationContext.jwt) {
      setRegistrationContext((prev: RegInfoType) => ({
        ...prev,
        jwt: jwt
      }));
    }
  }, [jwt]);

  useEffect(() => {
    if (window.innerWidth < 768) { // Only scroll on mobile devices
      window.scrollTo(0, 0); // Scroll to top of page on mobile
    }
  }, [registrationContext.currentPage]);

  /**
   * Debug dropdown at bottom of page for changing pages in development
   * @constructor
   */
  const DebugPageDropdown = () => {
    if (IS_PRODUCTION) {
      return <></>;
    }

    return (
      <>
        <FormControl fullWidth className="debugDropdown">
          <InputLabel>Page</InputLabel>
          <Select
            value={RegistrationPagesEnum[registrationContext.currentPage]}
            label="Page"
            onChange={(event: SelectChangeEvent) => {
              setRegistrationContext((prev: RegInfoType) => ({
                ...prev,
                // @ts-ignore
                currentPage: RegistrationPagesEnum[event.target.value as string]
              }));
            }}
          >
            {Object.keys(RegistrationPagesEnum).filter(key => isNaN(Number(key))).map((page: string) => (
              <MenuItem key={page} value={page}>{page}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </>
    );
  };

  useEffect(() => {
    setGlobalContext((prev: GlobalContextType) => ({
      ...prev,
      appBarPageSelected: 'register'
    }));
  }, [setGlobalContext]);

  switch (registrationContext.currentPage) {
    case RegistrationPagesEnum.VerifyEmailPage :
      return <><VerifyEmailPage theme={theme}/><DebugPageDropdown/></>;
    case RegistrationPagesEnum.CreateAccountPage:
      return <><CreateAccountPage theme={theme}/><DebugPageDropdown/></>;
    case RegistrationPagesEnum.SecurityQuestionsPage:
      return <><SecurityQuestionsPage theme={theme}/><DebugPageDropdown/></>;
    case RegistrationPagesEnum.ContactCustomerServicePage:
      return <><ContactCustomerServicePage theme={theme}/><DebugPageDropdown/></>;
    case RegistrationPagesEnum.ValidationPage:
      return (
        <>
          <ValidationPage
            registerFields={registerFields}
            setRegisterFields={setRegisterFields}
            phoneEditState={phoneEditState}
            setPhoneEditState={setPhoneEditState}
            formattedPhoneNumber={formattedPhoneNumber}
            setFormattedPhoneNumber={setFormattedPhoneNumber}
            phoneEditErrors={phoneEditErrors}
            setPhoneEditErrors={setPhoneEditErrors}
            registrationContext={registrationContext}
            setRegistrationContext={setRegistrationContext}
            theme={theme}
          />
          <DebugPageDropdown/>
        </>
      );
    case RegistrationPagesEnum.AddressPage:
      return (
        <>
          <AddressPage
            addressFields={addressFields}
            setAddressFields={setAddressFields}
            theme={theme}
          />
          <DebugPageDropdown/>
        </>
      );
    case RegistrationPagesEnum.RegisterCompletePage:
      return <><CompleteRegistrationSuccess theme={theme}/><DebugPageDropdown/></>;
    default:
      return (
        <Routes>
          <Route path="/" element={<><VerifyEmailPage theme={theme}/><DebugPageDropdown/></>}/>
          <Route path="/verify-email" element={<><CreateAccountPage theme={theme}/><DebugPageDropdown/></>}/>
        </Routes>
      );
  }
}

export default RegistrationPageRouter;