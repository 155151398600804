import {styled} from "@mui/styles";
import React, {useContext, useEffect, useState} from 'react';
import {LINK_CONTACT_US} from "../../../util/constants";
import RegistrationLayout from '../layout/registration-layout';
import {Card, Checkbox, FormControlLabel, InputLabel, Link} from '@mui/material';
import Tooltip, {TooltipProps, tooltipClasses} from '@mui/material/Tooltip';
import registration, {RegInfoType, RegistrationContext, RegistrationPagesEnum} from '../../../contexts/registration';
import {GlobalContext} from '../../../contexts/global';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {moreRewardsActivationCodeValidator, moreRewardsCardValidator} from '../../../util/validator';
import {handleSubmit, startUp} from './2-create-account-helpers';
import {useTranslation} from 'react-i18next';
import {CircularLoading} from '../../../components/loading/circular-loading';
import {useNavigate} from 'react-router-dom';
import {useSnackbar} from 'notistack';
import TextField from '@mui/material/TextField';
import AlertDialog from '../../../components/cancel-dialogue/cancelDialogue';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import './create-account.scss';
import {getJWTJsonPayload} from '../../../util/jwt';
import {autoFillFormInputs} from '../../../network-requests/register';


export type CreateAccountProps = {
  theme: string
}

interface JWTInfo {
  actionId: number;
  activationCode: string;
}

/**
 * Page you see after clicking verify email link
 * Example of a change
 * @constructor
 */
const CreateAccountPage = (props: CreateAccountProps) => {
  const { theme } = props;
  const navigate = useNavigate();
  const { globalContext } = useContext(GlobalContext);
  const { enqueueSnackbar } = useSnackbar();
  const { t, ready } = useTranslation();
  const { registrationContext, setRegistrationContext } = useContext(RegistrationContext);
  const [startUpDone, setStartUpDone] = useState(false);
  const [moreRewardsCard, setMoreRewardsCard] = useState(registrationContext.moreRewardsCard);
  const [moreRewardsCardHelperText, setMoreRewardsCardHelperText] = useState('');
  const [moreRewardsActivationCode, setMoreRewardsActivationCode] = useState(registrationContext.moreRewardsActivationCode);
  const [moreRewardsActivationCodeHelperText, setMoreRewardsActivationCodeHelperText] = useState('');
  const [needMrCard, setNeedMrCard] = useState(registrationContext.needMrCard);
  const [mrIsPrefilled, setMrIsPrefilled] = useState(false);
  const [alreadyRegistered, setAlreadyRegistered] = useState(false);
  const [openDialogue, setOpenDialogue] = useState<boolean>(false);
  const [openToolTip, setOpenToolTip] = useState<boolean>(false);

  const handleTooltipClose = () => {
    setOpenToolTip(false);
  };

  const handleTooltipOpen = () => {
    setOpenToolTip(true);
  };

  /* punt the user past the first More Rewards card page if they are coming
   * in from Choices. Only do if the user is on the CreateAccountPage */
  useEffect(() => {
    const skipMrCardPage = () => {
      let jwtInfo: JWTInfo;
      if (registrationContext.jwt !== '') jwtInfo = printDigitalJWT();
      if (theme === 'choices' || theme === 'meinhardt') {
        setRegistrationContext((prev: RegInfoType) => ({
          ...prev,
          currentPage: RegistrationPagesEnum.ValidationPage,
          theme
        }));
      }
      // We startUp() here because we skip the email verify page. Though we're confident the jwt is valid we still validate.
      //startUp will set the registrationContext.email & registrationContext.moreRewardsCard
      if (jwtInfo && jwtInfo.actionId === 20 && jwtInfo.activationCode) {
        startUp(
          registrationContext,
          setStartUpDone,
          setRegistrationContext,
          setAlreadyRegistered,
          setMoreRewardsCard,
          setMrIsPrefilled,
          enqueueSnackbar,
          t,
          navigate,
          theme
        );
        //We need to prep autofill for the form inputs here because we're skipping 3-security-questions where
        // it is setup on form submit.
        autoFillFormInputs(registrationContext.jwt, enqueueSnackbar, setRegistrationContext, t);
        // We need to set the currentPage to ValidationPage here because we're step 2 & 3 like meinhardt/choices
        setRegistrationContext((prev: RegInfoType) => ({
          ...prev,
          currentPage: RegistrationPagesEnum.ValidationPage,
          actionId: jwtInfo.actionId,
          theme
        }));
      }
    }
    skipMrCardPage();
  }, [undefined, theme]);


  const printDigitalJWT = (): JWTInfo | undefined => {
    try {
      const jwtInfo = getJWTJsonPayload(registrationContext.jwt) as JWTInfo;
      return jwtInfo;
    } catch (e) {
      console.error(e)
      return
    }
  }

  // take url jwt and validate, if valid render this page with email populated
  useEffect(() => {
    if (ready && registrationContext) {
      startUp(
        registrationContext,
        setStartUpDone,
        setRegistrationContext,
        setAlreadyRegistered,
        setMoreRewardsCard,
        setMrIsPrefilled,
        enqueueSnackbar,
        t,
        navigate,
        theme
      );
    }
  }, [ready, registrationContext.jwt]);

  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }}/>
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 425,
      // @ts-ignore
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }));

  if (!ready || !startUpDone) {
    return (
      <RegistrationLayout>
        <div className="loadingSpinnerContainer">
          <CircularLoading theme={theme}/>
        </div>
      </RegistrationLayout>
    )
  }

  if (alreadyRegistered) {
    return (
      <RegistrationLayout>
        <Typography variant="h2" component="h2" className="topHeader">
          {t('REGISTER.CREATE_ACCOUNT.REGISTRATION_COMPLETE_HEADER')}
        </Typography>
        <Typography
          component="p"
          variant="body2"
          className={theme === "choices" ? "topTextRegisterChoices" : "topTextRegister"}>
          {t('REGISTER.CREATE_ACCOUNT.REGISTRATION_COMPLETE')}
        </Typography>
        <div className="centeredContainer account-steps-nav">
          <Button
            type="submit"
            variant="contained"
            className="primaryButtonDefault"
            onClick={() => {
              setRegistrationContext((prev: RegInfoType) => ({
                ...prev,
                currentPage: RegistrationPagesEnum.VerifyEmailPage,
              }));
              navigate('/');
            }}
          >{t('BUTTONS.LOGIN')}
          </Button>
        </div>
      </RegistrationLayout>
    )
  }

  return (
    <>
      <AlertDialog
        openDialogue={openDialogue}
        setOpenDialogue={setOpenDialogue}
        setRegistrationContext={setRegistrationContext}
        theme={theme}
      />
      <RegistrationLayout>
        {<form
          onSubmit={(event: React.FormEvent<HTMLFormElement>) => handleSubmit(
            event,
            registrationContext,
            setRegistrationContext,
            needMrCard,
            setMoreRewardsCardHelperText,
            moreRewardsCard,
            setMoreRewardsActivationCodeHelperText,
            moreRewardsActivationCode,
            enqueueSnackbar,
            t,
            theme
          )}
          noValidate
          className={globalContext.referer === 'choices' ? 'choicesWhiteBox' : ''}
        >
          <Typography variant="h2" component="h2" className="topHeader">
            {t('REGISTER.HEADERS.CREATE_ACCOUNT')}
          </Typography>
          <Typography
            component="p"
            variant="body2"
            className={theme === "choices" ? "topTextRegisterChoices" : "topTextRegister"}>
            {globalContext.referer === "choices" ? t('REGISTER.CREATE_ACCOUNT.CHOICES.TOP_TEXT-CREATE') :
              <div dangerouslySetInnerHTML={{ __html: t('REGISTER.CREATE_ACCOUNT.TOP_TEXT-CREATE') }}/>}
          </Typography>
          <Card className="create-white-box">
            <div className="centeredContainer">
              <div className="infoBar infoBarThin">
                <div className="info"><h3><strong>Step 1 / 3:</strong> Card Details</h3></div>
              </div>
              <div className="innerColumn">
                {!mrIsPrefilled &&
                  <FormControlLabel
                    id="iDontHaveCardBtn"
                    className="center-flex no-card"
                    label={t('REGISTER.CREATE_ACCOUNT.I_DONT_HAVE_CARD') as string}
                    control={
                      <Checkbox
                        checked={needMrCard}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          setNeedMrCard(event.target.checked);
                        }}
                      />}
                  />
                }
                {!needMrCard &&
                  <img
                    style={{ marginBottom: '18px' }}
                    className="center-img choicesHide"
                    src={require('../../../images/tool-tip-card.png')} alt="More Rewards Card Tool Tip"
                  />}
                <InputLabel className="inputLabel">{t('BASIC.EMAIL')}</InputLabel>
                <TextField
                  disabled
                  className="fullWidth"
                  required
                  autoComplete="email"
                  value={registrationContext.email}
                />
                {!needMrCard && <>
                  <InputLabel
                    className="inputLabel inputLabelTop choicesHide">{t('BASIC.MORE_REWARDS_CARD_NUM#')}
                  </InputLabel>
                  <TextField
                    variant="outlined"
                    type="tel" // uses number pad keyboard on mobile devices
                    className="fullWidth choicesHide"
                    required
                    // autoFocus
                    error={moreRewardsCardHelperText !== ''}
                    helperText={moreRewardsCardHelperText}
                    value={moreRewardsCard}
                    inputProps={{ maxLength: 11 }}
                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                      let input = event.target.value.replace(/[^0-9]/g, '');
                      setMoreRewardsCard(input);
                      setMoreRewardsCardHelperText(moreRewardsCardValidator(input, t));
                      setMrIsPrefilled(false);
                    }}
                  />
                  <div className="tooltipAC">
                    <InputLabel className="inputLabel inputLabelTop choicesHide">{t('BASIC.AC#')}</InputLabel>
                    <ClickAwayListener onClickAway={handleTooltipClose}>
                      <div>
                        <HtmlTooltip
                          open={openToolTip}
                          onClose={handleTooltipClose}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          title={
                            <>
                              <Typography sx={{ fontSize: '13px', margin: '12px', color: '#0c609f' }}>Some older cards
                                do not have an
                                AC#, if your card does not have
                                one please visit the customer service desk at your nearest store to obtain a card with
                                an AC#,
                                or<Link
                                  href={LINK_CONTACT_US}
                                  target="_blank"
                                  className="link-orange"
                                >contact us</Link>&nbsp;to request a new card to be mailed to you.</Typography>
                            </>
                          }
                        >
                          <InfoOutlinedIcon onClick={handleTooltipOpen} sx={{
                            color: '#00589c',
                            minWidth: '0px',
                            '&:hover': {
                              cursor: 'pointer',
                            },
                            marginLeft: '5px',
                            marginTop: '6px'
                          }}/>
                        </HtmlTooltip>
                      </div>
                    </ClickAwayListener>
                  </div>
                  <TextField
                    variant="outlined"
                    className="fullWidth choicesHide"
                    required
                    error={moreRewardsActivationCodeHelperText !== ''}
                    helperText={moreRewardsActivationCodeHelperText}
                    inputProps={{ maxLength: 6 }}
                    value={moreRewardsActivationCode}
                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                      setMoreRewardsActivationCode(event.target.value);
                      setMoreRewardsActivationCodeHelperText(moreRewardsActivationCodeValidator(event.target.value, t));
                    }}
                  />
                </>}
              </div>
            </div>
            <div className="centeredContainer account-steps-nav-cancel borderTop">
              <Button
                disabled={registrationContext.email.length === 0}
                id="nextStepBtn"
                type="submit"
                variant="contained"
                className="primaryButtonDefault"
              >{t('BUTTONS.NEXT')}</Button>
              <Button
                variant="outlined"
                className="cancelButtonDefault"
                onClick={() => {
                  setOpenDialogue(true)
                }}
              >{t('BUTTONS.CANCEL')}</Button>
            </div>
          </Card>
        </form>}
      </RegistrationLayout>
    </>
  );
};

export default CreateAccountPage;
