import {
  LINK_CONTACT_US, 
  LINK_CONTACT_US_CHOICES, 
  LINK_CONTACT_US_MEINHARDT,
  LINK_TERMS_AND_CONDITIONS,
  LINK_TERMS_AND_CONDITIONS_CHOICES,
  LINK_TERMS_AND_CONDITIONS_MEINHARDT
} from "./constants";

export const getContactUsLink = (theme: string) => {
  switch (theme) {
    case 'choices':
      return LINK_CONTACT_US_CHOICES;
    case 'meinhardt':
      return LINK_CONTACT_US_MEINHARDT;
    default:
      return LINK_CONTACT_US;
  }
};

export const getTermsAndConditionsLink = (theme: string) => {
  switch (theme) {
    case 'choices':
      return LINK_TERMS_AND_CONDITIONS_CHOICES;
    case 'meinhardt':
      return LINK_TERMS_AND_CONDITIONS_MEINHARDT;
    default:
      return LINK_TERMS_AND_CONDITIONS;
  }
};