import React, {useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import {selectOrUnselectAll} from './helper-functions/helpers';
import Checkbox from '@mui/material/Checkbox';
import {updateUsersEmailSubscriptions} from '../../../network-requests/resources';
import {Checkboxes} from '../../../contexts/types/myAccount';
import {CheckboxState} from './emailSubscriptionsTypes';
import {EmailSubscriptionsProps} from '../myAccountTypes';
import {Grid} from '@mui/material';
import {profileInfoAtom} from '../../../jotai-state-management/profile-global-state';
import {LoadingButton} from '@mui/lab';
import {useAtom} from 'jotai';
import {useSnackbar} from 'notistack';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import pushRouteChangeEvent from '../../../util/google-tag-manager';
import './emailSubscriptions.scss';

export default function EmailSubscriptions(props: EmailSubscriptionsProps) {
  const {
    loading,
    checkboxDivs,
    setCheckboxState,
    checkboxState,
    apiIsLoading,
    setApiIsLoading,
    theme,
    setSelectedTab
  } = props;
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [, updateProfileInfo] = useAtom(profileInfoAtom);

  const cancelButtonClick = async (
    checkboxState: CheckboxState,
    setCheckboxState: React.Dispatch<React.SetStateAction<CheckboxState>>
  ) => {
    setCheckboxState((prev: any) => ({
      ...prev,
      initialCheckboxesState: checkboxState.initialCheckboxesSnapshot
    }));
  };

  useEffect(() => {
    if (theme === 'choices' || theme === 'meinhardt') {
      setSelectedTab(0);
      navigate('/userPage/home');
    }
    pushRouteChangeEvent('Profile: EmailSubscriptions');
  }, []);

  const submitCheckBoxChange = async (
    checkboxesState: Checkboxes,
    updateProfileInfo: any,
    setApiIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    enqueueSnackbar: any
  ): Promise<void> => {
    await setApiIsLoading(true);
    try {
      await updateUsersEmailSubscriptions(checkboxesState);
      updateProfileInfo((prev: any) => ({ ...prev, emailSubscriptions: checkboxState.initialCheckboxesState }));
      enqueueSnackbar('Your email subscription settings have been updated', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Something went wrong when trying to do this. Please try again.', { variant: 'Error' });
    }
    await setApiIsLoading(false);
  };

  return (
    <div>
      <Box>
        <Paper elevation={0}>
          <div className="emailSubscriptions">
            <div className="infoBar infoBarThin">
              <div><h3>Manage Your Email Subscriptions</h3><p>Get first access to the weekly deals, upcoming events &amp; promotions, and personalised offers straight to your inbox.</p></div>
            </div>
            <div className="centeredContainer emailSubscriptions">
              <div className="innerColumn">
                <Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                  <div className="emailPromoSubPage">Want to win FREE groceries for a year? Subscribe to emails below to be entered into our monthly draw. Conditions apply. Learn more at <a target="_blank" href="https://www.morerewards.ca/winfreegroceries">morerewards.ca/winfreegroceries</a></div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <div className="checkboxesConfirmation">
                      <div className="checkboxAndTextManage">
                        <Checkbox
                          disabled={loading || apiIsLoading}
                          data-testid="checkboxChangeAll"
                          checked={checkboxState.anyCheckboxesAreSelected}
                          onChange={
                            () => {
                              checkboxState.anyCheckboxesAreSelected
                                ? selectOrUnselectAll(setCheckboxState, 'uncheckAll')
                                : selectOrUnselectAll(setCheckboxState, 'checkAll');
                            }
                          }
                          inputProps={{
                            'aria-label': 'select all'
                          }}
                        />
                        <div>
                          {checkboxState.anyCheckboxesAreSelected
                            ?
                            <Typography>
                              Unselect all
                            </Typography>
                            :
                            <Typography>
                              Select all
                            </Typography>
                          }
                        </div>
                      </div>
                    </div>
                    <div className="checkboxes">
                      {checkboxDivs}
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div className="editButtons">
                {loading || apiIsLoading
                  ?
                  <LoadingButton
                    loading
                    className="primaryButtonDefault"
                    loadingPosition="start"
                    sx={{ mt: 3, mb: 2 }}
                    startIcon={<SaveIcon/>}
                    variant="outlined"
                  >
                    Saving...
                  </LoadingButton>
                  :
                  <Button
                    disabled={checkboxState.checkboxSnapshotsMatch}
                    type="submit"
                    className="primaryButtonDefault"
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    onClick={() => submitCheckBoxChange(
                      checkboxState.initialCheckboxesState,
                      updateProfileInfo,
                      setApiIsLoading,
                      enqueueSnackbar
                    )}
                  >
                    Save
                  </Button>
                }
                {!checkboxState.checkboxSnapshotsMatch
                  ?
                  <Button
                    disabled={checkboxState.checkboxSnapshotsMatch}
                    type="submit"
                    className="cancelButtonDefault"
                    variant="contained"
                    onClick={() => cancelButtonClick(checkboxState, setCheckboxState)}
                    sx={{ mt: 3, mb: 2, marginLeft: '5px' }}>
                    Cancel
                  </Button>
                  :
                  <>
                  </>
                }
              </div>
            </div>
          </div>
        </Paper>
      </Box>
    </div>
  );
}