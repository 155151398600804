import React, { useState, useEffect, useContext } from 'react';
import { TextField, CircularProgress, Button } from '@mui/material';
import { emailValidator } from '../../../util/validator';
import { handleVerifyEmailExists } from '../verify-email/helpers';
import { GlobalContext } from '../../../contexts/global';
import { useTranslation } from 'react-i18next';
import {DigitalRegisterFields } from '../verify-email/digital-verify-email';
import {ValidationStatus} from '../verify-email/digital-verify-email'
import {ValidateDone, ValidateError, ValidateLoading} from "../../../components/loading/status-icon";

type EmailControllerProps = {
  digitalRegisterFields: DigitalRegisterFields;
  setDigitalRegisterFields: React.Dispatch<React.SetStateAction<DigitalRegisterFields>>;
  validationStatus: ValidationStatus,
  setValidationStatus: React.Dispatch<React.SetStateAction<ValidationStatus>>
};

enum RequestTimeout {
  "five" = 5,
  "ten" = 10,
  "twenty"= 20,
  "thirty"= 30,
  "sixty"= 60
}

const EmailController: React.FC<EmailControllerProps> = (
  {
    digitalRegisterFields,
    setDigitalRegisterFields,
    validationStatus,
    setValidationStatus,
  }
) => {
  const { t } = useTranslation();
  const { globalContext } = useContext(GlobalContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [requestCount, setRequestCount] = useState<number>(0);
  const [waitTime, setWaitTime] = useState<number>(0);
  const [coolDown, setCoolDown] = useState<number>(0);

  useEffect(() => {
  }, []); //

  useEffect(() => {
    if (waitTime > 0) {
      const timer = setTimeout(() => setWaitTime(waitTime - 1), 1000);
      return () => clearTimeout(timer);
    } else if (waitTime === 0 && requestCount >= 5) {
      setLoading(true);
      handleVerifyEmailExists(digitalRegisterFields.email, setValidationStatus, setDigitalRegisterFields, t).finally(() => {
        setLoading(false);
      });
    }
  }, [waitTime]);

  useEffect(() => {
    if (coolDown > 0) {
      const coolDownTimer = setTimeout(() => setCoolDown(coolDown - 1), 1000);
      return () => clearTimeout(coolDownTimer);
    } else if (coolDown <= 0 && requestCount >= 5) {
      setRequestCount(0);
    }
  }, [coolDown]);

  /**
   * This function receives the onChange event object and extracts the value (email) of the email input field.
   * The function then calls emailValidator() to validate the email syntax against the EMAIL_REGEX.
   * It then sets the fields for display and finally returns a boolean indicating if the email is a valid email or not.
   * @param event { React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> }
   * @param email { string }
   */
  const validateEmailInput = (email: string ) => {
    const validationMessage = emailValidator(email, t);

    setDigitalRegisterFields((prev: any) => ({
      ...prev,
      email: email,
      emailHelperText: validationMessage,
    }));

    return validationMessage === '';
  }

  const handleEmailChange = (event:  React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, email: string, isValidEmail: boolean) => {
    const trimmedEmail = email.trim();
    if (isValidEmail && requestCount < 5) {
      setLoading(true);
      setRequestCount(requestCount + 1);
      handleVerifyEmailExists(trimmedEmail, setValidationStatus, setDigitalRegisterFields, t).finally(() => {
        setLoading(false);
      });
    } else if (requestCount >= 5) {
      setLoading(true);
      setRequestCount(requestCount + 1);
      setCoolDown(90); // Reset coolDown timer
      setValidationStatus((prev: ValidationStatus) => ({
        ...prev,
        available: false,
        isValid: true,
        email: trimmedEmail,
      }));
      let newWaitTime;
      switch (requestCount) {
        case 5:
          newWaitTime = RequestTimeout.five;
          break;
        case 6:
          newWaitTime = RequestTimeout.ten;
          break;
        case 7:
          newWaitTime = RequestTimeout.twenty;
          break;
        case 8:
          newWaitTime = RequestTimeout.thirty;
          break;
        default:
          newWaitTime = RequestTimeout.sixty;
      }
      setWaitTime(newWaitTime);
    }
  };

  return (
    <div>
      <div className='email-container'>
        <TextField
          className="fullWidth digital-email-input"
          required
          id="regEmail"
          name="email"
          autoComplete="email"
          autoFocus
          error={digitalRegisterFields.emailHelperText !== ''}
          helperText={digitalRegisterFields.emailHelperText}
          onChange={(event) => {
            const emailValue = event.target.value;
            validateEmailInput(emailValue);
          }}
          onBlur={ (event) => {
            const emailValue = event.target.value;
            const isValidEmail = validateEmailInput(emailValue);
            if (isValidEmail) {
              setValidationStatus((prev: ValidationStatus) => ({
                ...prev,
                isValidating: true,
              }))
              handleEmailChange(event, emailValue, isValidEmail);
            } else {
              setValidationStatus((prev: ValidationStatus) => ({
                ...prev,
                isValid: false,
                available: false,
                email: emailValue
              }));
            }
          }}
          inputProps={{
            'aria-label': 'Email Address text field',
          }}
        />
        {validationStatus.isValidating ? (
          <ValidateLoading
            minHeight={30}
            minWidth={30}
            marginTop={0}
            marginBottom={0}
            color='blue'
            size={30}
          />
        ) : validationStatus.isValid && validationStatus.available ? (
          <ValidateDone color={'green'}/>
        ) : (validationStatus.isValid && !validationStatus.available) || validationStatus.error ? (
          <ValidateError color={'red'}/>
        ) : validationStatus.email === '' ? <></>
          : (
            <></>
          )}
      </div>
    </div>
  );
};

export default EmailController;