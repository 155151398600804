import React, {useContext, useEffect, useMemo, useState} from 'react';
import {Route, Routes} from 'react-router-dom';
import DigitalVerifyEmailPage, {DigitalRegisterFields} from './verify-email/digital-verify-email';
import {RegistrationContext} from '../../contexts/registration';
import {GlobalContextType} from "../../contexts/types/global";
import {GlobalContext} from '../../contexts/global';
import {IS_PRODUCTION} from '../../util/constants';
import './register-router.scss';
import DigitalMoreRewardsCard from '../my-account/card/digitalCard';
import pushRouteChangeEvent from "../../util/google-tag-manager";


export type DigitalCardRegistrationPageRouterProps = {
  theme: string
}

/**
 * This Page component controls the routing to the digital form registration page and the registration completion page
 * consisting of MR card number, activation code, and barcode
 * @param props {DigitalCardRegistrationPageRouterProps}
 * @constructor
 */
const DigitalCardRegistrationPageRouter = (props: DigitalCardRegistrationPageRouterProps) => {
  const { theme } = props;
  const { setGlobalContext } = useContext(GlobalContext);
  const { registrationContext, setRegistrationContext } = useContext(RegistrationContext);
  const [digitalRegisterFields, setDigitalRegisterFields] = useState<DigitalRegisterFields>({
    email: '',
    emailHelperText: '',
    fName: '',
    fNameHelperText: '',
    lName: '',
    lNameHelperText: '',
    isEmailSubscribed: null,
    isEmailSubscribedHelperText: ''
  });

  useEffect(() => {
    setGlobalContext((prev: GlobalContextType) => ({
      ...prev,
      appBarPageSelected: 'register-digital'
    }));
  }, [setGlobalContext]);

  useEffect(() => {
    pushRouteChangeEvent('Register: DigitalAccountComplete');
  }, []);

  return (
    <Routes>
      <Route path="/" element={
        <>
          <DigitalVerifyEmailPage
            digitalRegisterFields={digitalRegisterFields}
            setDigitalRegisterFields={setDigitalRegisterFields}
            registrationContext={registrationContext}
            setRegistrationContext={setRegistrationContext}
            theme={theme}
          />
        </>}
      />
      <Route path="/complete" element={
        <>
          <DigitalMoreRewardsCard
            digitalRegisterFields={digitalRegisterFields}
            setDigitalRegisterFields={setDigitalRegisterFields}
            registrationContext={registrationContext}
            setRegistrationContext={setRegistrationContext}
          />
        </>}/>
    </Routes>
  )
}

export default DigitalCardRegistrationPageRouter;