import React, {useEffect, useState} from 'react';
import { useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import {CircularProgress, Link} from '@mui/material';
import MoreRewardsCardDisplay from '../../../components/card-display/mr-card-display';
import {RegInfoType, RegistrationPagesEnum} from "../../../contexts/registration";
import {DigitalRegisterFields} from "../../register/verify-email/digital-verify-email";
import WalletButton from "../../../components/wallet-buttons/add-wallet-buttons";
import './digitalCard.scss';


export type DigitaCardPageProps = {
  digitalRegisterFields: DigitalRegisterFields
  setDigitalRegisterFields: React.Dispatch<React.SetStateAction<DigitalRegisterFields>>,
  registrationContext: RegInfoType,
  setRegistrationContext: React.Dispatch<React.SetStateAction<RegInfoType>>,
}

interface AccountData {
  morerewardscard: string;
  activationcode: string;
  digitalMRJwt: string;
}
export default function DigitalMoreRewardsCard(props: DigitaCardPageProps) {
  const {
    digitalRegisterFields,
    setDigitalRegisterFields,
    registrationContext,
    setRegistrationContext,
  } = props;
  const location = useLocation();
  const { morerewardscard, activationcode, digitalMRJwt } = location.state as AccountData || {}
  const [loading, setLoading] = useState(false);
  useEffect( () => {
    setRegistrationContext( (prev:RegInfoType) => ({
      ...prev,
      currentPage:RegistrationPagesEnum.DigitalRegisterCompletePage
    }))
  }, [registrationContext.currentPage])
  return (
    <div>
      <Box>
        <Paper elevation={0}>
          {loading ?
              <div className="loadingProfile">
                <Box>
                  <CircularProgress className="circularProgressSpinner"/>
                </Box>
              </div>
            :
              <>
                <div className="infoBar infoBarMobile digital">
                  <div className="reg-info">
                    <div className="info">
                      <h3>Card Number</h3>
                      <span className="largerText fontweightNormal">{morerewardscard}</span>
                    </div>
                  </div>
                  <div className="reg-info">
                    <div className="info">
                      <h3>Activation Code</h3>
                      <span className="largerText fontweightNormal">{activationcode}</span>
                    </div>
                  </div>
                </div>
                <div className="moreRewardsCard">
                 <MoreRewardsCardDisplay card={morerewardscard}/>
                </div>
                <WalletButton card={morerewardscard} digitalMRJwt={digitalMRJwt}/>
              </>
          }
        </Paper>
      </Box>
    </div>
  );
}