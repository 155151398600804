import React from 'react';
import {CircularProgress} from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

export type MyCircularProgressProps = {
  theme: string; // Update 'default' to the actual default theme value
}

export type ValidateLoadingProps = {
  minWidth: number;
  minHeight: number;
  marginTop: number;
  marginBottom: number;
  color: string;
  size: number
}

export type StatusIconProps = {
  color: string;
}

const ValidateLoading = (props: ValidateLoadingProps ) => {
  const {
    minWidth,
    minHeight,
    marginTop,
    marginBottom,
    color,
    size
  } = props;
  return (
    <div className="loadingContainer digital-icon">
      <CircularProgress
        size={size}
        style={{
          minWidth: `${minWidth}px`,
          minHeight: `${minHeight}px`,
          marginTop: `${marginTop}px`,
          marginBottom: `${marginBottom}px`,
          color:  color
        }}
      />
    </div>
  )
}
const ValidateDone = (props: StatusIconProps)  => {
  const {color} = props
  return (
    <div className="loadingContainer digital-icon">
      <CheckIcon fontSize='large' sx={{color: color}} />
    </div>

  )
}
const ValidateError = (props: StatusIconProps) => {
  const {color} = props
  return (
    <div className="loadingContainer digital-icon">
      <ErrorOutlineIcon fontSize='large' sx={{color: color}} />
    </div>
  )
}


export  {ValidateLoading, ValidateDone, ValidateError}