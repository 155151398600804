import React from 'react';
import {Link, Typography} from "@mui/material";
import {LINK_CONTACT_US} from "../../../../util/constants";
import { getContactUsLink } from '../../../../util/helpers';
import {formatPhoneNumber} from "../../../../util/phoneFormatter";
import {PhoneNumbers} from "../../profile/profile";
import {AddressBookDivs, CreateAddressResponse, EditAddressResponse} from "../addressesTypes";
import {BillingShippingResponse, DeleteResponse} from "../addressesTypes";
import {HelperText} from '../addressesTypes';
import {ProfileInfoType, SingleAddress} from '../../../../contexts/types/myAccount';
import {generateAddresses} from '../generated-addresses/generateAddresses';
import {
  createUserAddress,
  deleteUserAddress,
  updateUsersAddress
} from '../../../../network-requests/resources';

// array to store every province for the provinces dropdown menus
const provinces = [
  'Alberta',
  'British Columbia',
  'Manitoba',
  'New Brunswick',
  'Newfoundland',
  'Northwest Territories',
  'Nova Scotia',
  'Nunavut',
  'Ontario',
  'Prince Edward Island',
  'Quebec',
  'Saskatchewan',
  'Yukon Territories'
];

export const provincesConverter = [
  { short: 'AB', long: 'Alberta' },
  { short: 'BC', long: 'British Columbia' },
  { short: 'NB', long: 'New Brunswick' },
  { short: 'NL', long: 'Newfoundland' },
  { short: 'NT', long: 'Northwest Territories' },
  { short: 'NS', long: 'Nova Scotia' },
  { short: 'NU', long: 'Nunavut' },
  { short: 'PE', long: 'Prince Edward Island' },
  { short: 'QC', long: 'Quebec' },
  { short: 'SK', long: 'Saskatchewan' },
  { short: 'ON', long: 'Ontario' },
  { short: 'MB', long: 'Manitoba' },
  { short: 'YT', long: 'Yukon Territories' }
];

/* province codes that come back from Canada post API are shorthand formatted
 * convert these back to longhand format for the provinces dropdown menu */
const convertProvinceCodeToLonghand = (
  selectedProvince: string,
  setSelectedProvince: React.Dispatch<React.SetStateAction<string>>
) => {
  provincesConverter.forEach((province: { short: string, long: string }) => {
    if (province.short === selectedProvince) {
      setSelectedProvince(province.long);
    }
  });
};

const convertProvinceCodeToLonghandBillingShipping = (
  selectedProvince: string,
) => {
  let provinceName = 'British Columbia';
  provincesConverter.some((province: { short: string, long: string }) => {
    if (province.short === selectedProvince) {
      provinceName = province.long;
    }
  });
  return provinceName;
};

export const deleteAddressOnFrontend = async (
  profileInfo: ProfileInfoType,
  response: DeleteResponse
) => {
  for (let i = 0; i < profileInfo.addresses.length; i++) {
    if (profileInfo.addresses[i] && profileInfo.addresses[i].id === response.data.customer.deleteAddress.id) {
      profileInfo.addresses.splice(i, 1);
    }
  }
}

const clearBillingInformationFromAddresses = async (
  profileInfo: ProfileInfoType,
) => {
  for (let i = 0; i < profileInfo.addresses.length; i++) {
    profileInfo.addresses[i].defaultBillingAddress = false;
  }
  return profileInfo;
}

const clearShippingInformationFromAddresses = async (
  profileInfo: ProfileInfoType,
) => {
  for (let i = 0; i < profileInfo.addresses.length; i++) {
    profileInfo.addresses[i].defaultShippingAddress = false;
  }
  return profileInfo;
}

/* updating the profileInfo state directly like this without
 * a setter seems like a bad idea but it works for now */
export const constructNewAddressObject = async (
  profileInfo: ProfileInfoType,
  responseJSON: CreateAddressResponse | EditAddressResponse | DeleteResponse | BillingShippingResponse
) => {
  try {
    let customerAddressObj;
    if ('createAddress' in responseJSON.data.customer) { // Type checking for a created address
      customerAddressObj = responseJSON.data.customer.createAddress;
      return profileInfo.addresses.push(customerAddressObj);
    }
    if ('updateAddress' in responseJSON.data.customer) { // Type checking for an updated address
      customerAddressObj = responseJSON.data.customer.updateAddress;
      for (let i = 0; i < profileInfo.addresses.length; i++) {
        if (profileInfo.addresses[i].id === customerAddressObj.id) {
          profileInfo.addresses[i] = customerAddressObj;
        }
      }
    }
  } catch (e) {
    console.error(e)
  }
};

// handle closing the edit address dialogue
const handleClose = async (
  closeType: string,
  singleAddressDialogue: SingleAddress,
  selectedProvince: string,
  setSavingChange: React.Dispatch<React.SetStateAction<boolean>>,
  enqueueSnackbar: any,
  onClose: Function,
  setHelperText: React.Dispatch<React.SetStateAction<HelperText>>,
  setSingleAddressDialogue: React.Dispatch<React.SetStateAction<SingleAddress>>,
  profileInfo: ProfileInfoType,
  handleClickOpen: Function,
  addressToEdit: SingleAddress,
  handleDelete: Function,
  setOpenDeleteDialogue: React.Dispatch<React.SetStateAction<boolean>>,
  setAddressToDelete: React.Dispatch<React.SetStateAction<SingleAddress>>,
  handleBillingShippingUpdate: Function,
  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setAddressToEdit: React.Dispatch<React.SetStateAction<SingleAddress>>,
  setAddressBookDivs: React.Dispatch<React.SetStateAction<AddressBookDivs>>,
  theme: string,
  setLoadingBillingShippingUpdate: React.Dispatch<React.SetStateAction<boolean>>
) => {
  let response: any;
  setHelperText(prevHelperText => ({
    ...prevHelperText,
    firstName: '',
    lastName: '',
    street1: '',
    cityName: '',
    postalCode: '',
    primaryPhone: ''
  }));
  if (closeType === 'cancel') {
    onClose(setOpen, setAddressToEdit);
    return setSingleAddressDialogue(null);
  }
  if (closeType === 'create') {
    setSavingChange(true);
    response = await createUserAddress(
      singleAddressDialogue,
      selectedProvince,
      setSavingChange,
      enqueueSnackbar,
      theme
    )
  }
  if (closeType === 'save') {
    setSavingChange(true);
    response = await updateUsersAddress(
      singleAddressDialogue,
      selectedProvince,
      setSavingChange,
      enqueueSnackbar,
      theme
    );
  }
  if (!response || response.message === 'not found' || response.errorMessage) {
    setSavingChange(false);
    return enqueueSnackbar(
      <>
        <Typography
          sx={{ marginRight: '5px', marginLeft: '5px', cursor: 'pointer' }}>
          An unexpected error occurred when trying to do this. Please try again. If this issue persists
          please&nbsp;
          <Link
            href={getContactUsLink(theme)}
            target="_blank"
            className="link-white">
            contact us
          </Link>
        </Typography>
      </>,
      { variant: 'error' }
    );
  }
  if (response) {
    const responseJSON: CreateAddressResponse | EditAddressResponse = await JSON.parse(response);
    await constructNewAddressObject(profileInfo, responseJSON);
    await generateAddresses(
      profileInfo,
      handleClickOpen,
      setOpenDeleteDialogue,
      setAddressToDelete,
      handleBillingShippingUpdate,
      enqueueSnackbar,
      setOpen,
      setAddressToEdit,
      setAddressBookDivs,
      theme,
      setLoadingBillingShippingUpdate
    );
    setTimeout(() => {
      if (setSavingChange) {
        setSavingChange(false);
      }
    }, 100);
    closeType === 'save' ?
      enqueueSnackbar('Address updated', { variant: 'success' }) :
      enqueueSnackbar('Address created', { variant: 'success' })
  }
  onClose(setOpen, setAddressToEdit);
  setSingleAddressDialogue(addressToEdit);
  setHelperText((prev: HelperText) => ({
    ...prev,
    firstName: '',
    lastName: '',
    street1: '',
    cityName: '',
    postalCode: '',
    primaryPhone: ''
  }));
};

const handleClickOpen = async (
  address: SingleAddress,
  setAddressToEdit: React.Dispatch<React.SetStateAction<SingleAddress>>,
  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
  type: string
) => {
  if (address) {
    setAddressToEdit(address);
  }
  if (type === 'new') {
  }
  setOpen(true);
};

const onClose = (
  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setAddressToEdit: React.Dispatch<React.SetStateAction<SingleAddress>>,
) => {
  setOpen(false);
  setAddressToEdit(null);
};

const handleDelete = async (
  address: SingleAddress,
  setLoadingDelete: React.Dispatch<React.SetStateAction<boolean>>,
  profileInfo: ProfileInfoType,
  setOpenDeleteDialogue: React.Dispatch<React.SetStateAction<boolean>>,
  setAddressToDelete: React.Dispatch<React.SetStateAction<SingleAddress>>,
  enqueueSnackbar: any,
  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setAddressToEdit: React.Dispatch<React.SetStateAction<SingleAddress>>,
  setAddressBookDivs: React.Dispatch<React.SetStateAction<AddressBookDivs>>,
  theme: string,
  setLoadingBillingShippingUpdate: React.Dispatch<React.SetStateAction<boolean>>
) => {
  try {
    setLoadingDelete(true);
    const response: DeleteResponse = await deleteUserAddress(address, enqueueSnackbar, theme);
    if (response && response.data.customer.deleteAddress.id) {
      await deleteAddressOnFrontend(profileInfo, response);
      await generateAddresses(
        profileInfo,
        handleClickOpen,
        setOpenDeleteDialogue,
        setAddressToDelete,
        handleBillingShippingUpdate,
        enqueueSnackbar,
        setOpen,
        setAddressToEdit,
        setAddressBookDivs,
        theme,
        setLoadingBillingShippingUpdate
      );
      setOpenDeleteDialogue(false);
      setLoadingDelete(false);
      enqueueSnackbar('Address deleted', { variant: 'success' });
    }
  } catch (e) {
    enqueueSnackbar('Something went wrong deleting your address. Please try again', { variant: 'error' });
    console.error(e);
    setLoadingDelete(false);
  }
}

const handleBillingShippingUpdate = async (
  address: SingleAddress,
  profileInfo: ProfileInfoType,
  setOpenDeleteDialogue: React.Dispatch<React.SetStateAction<boolean>>,
  setAddressToDelete: React.Dispatch<React.SetStateAction<SingleAddress>>,
  type: string,
  enqueueSnackbar: any,
  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setAddressToEdit: React.Dispatch<React.SetStateAction<SingleAddress>>,
  setAddressBookDivs: React.Dispatch<React.SetStateAction<AddressBookDivs>>,
  theme: string,
  setLoadingBillingShippingUpdate: React.Dispatch<React.SetStateAction<boolean>>
) => {
  let missingRequiredField: string[] = [];
  // validate inputs
  if (!address.firstName) {
    missingRequiredField.push("First Name");
  }
  if (!address.lastName) {
    missingRequiredField.push("Last Name");
  }
  if (!address.primaryPhone) {
    missingRequiredField.push("Phone");
  }
  if (!address.street1) {
    missingRequiredField.push("Address Line 1");
  }
  if (!address.cityName) {
    missingRequiredField.push("City");
  }
  if (!address.provinceCode) {
    missingRequiredField.push("Province");
  }
  if (!address.postalCode) {
    missingRequiredField.push("Postal Code");
  }
  if (missingRequiredField.length > 0) {
    enqueueSnackbar(
      <>
        <Typography
          sx={{ marginRight: '5px', marginLeft: '5px', cursor: 'pointer' }}>
          Oops, the address you selected is missing some details ({missingRequiredField.join(', ')}). To make this change please edit your address first.
        </Typography>
      </>,
      { variant: 'error' }
    )
    return;
  }

  if (type === 'shipping') {
    address.defaultShippingAddress = true;
  }
  if (type === 'billing') {
    address.defaultBillingAddress = true;
  }
  try {
    await setLoadingBillingShippingUpdate(true);
    const response = await updateUsersAddress(
      address,
      convertProvinceCodeToLonghandBillingShipping(address.provinceCode),
      null,
      enqueueSnackbar,
      theme
    );
    const responseJSON: BillingShippingResponse = JSON.parse(response);
    let updatedProfileInfo: ProfileInfoType;
    let notificationMessage: string;
    if (responseJSON && responseJSON.data.customer.updateAddress.defaultBillingAddress) {
      updatedProfileInfo = await clearBillingInformationFromAddresses(profileInfo);
      notificationMessage = 'Updated your default billing address';
    }
    if (responseJSON && responseJSON.data.customer.updateAddress.defaultShippingAddress) {
      updatedProfileInfo = await clearShippingInformationFromAddresses(profileInfo);
      notificationMessage = 'Updated your default shipping address'
    }
    await constructNewAddressObject(updatedProfileInfo, responseJSON);
    await generateAddresses(
      profileInfo,
      handleClickOpen,
      setOpenDeleteDialogue,
      setAddressToDelete,
      handleBillingShippingUpdate,
      enqueueSnackbar,
      setOpen,
      setAddressToEdit,
      setAddressBookDivs,
      theme,
      setLoadingBillingShippingUpdate
    )
    enqueueSnackbar(notificationMessage, { variant: 'success' });
    setLoadingBillingShippingUpdate(false); // keep spinner going for 0 seconds for consistent UX and reduce spam
  } catch (e) {
    console.error(e);
    enqueueSnackbar(
      <>
        <Typography
          sx={{ marginRight: '5px', marginLeft: '5px', cursor: 'pointer' }}>
          An unexpected error occurred editing your addresses. Please try again. If this issue persists please&nbsp;
          <Link
            href={LINK_CONTACT_US}
            target="_blank"
            className="link-white"
          >contact us
          </Link>
        </Typography>
      </>,
      { variant: 'error' }
    )
    setLoadingBillingShippingUpdate(false); // keep spinner going for 2 seconds for consistent UX and reduce spam
  }
}

const updatePhoneNumbersAddress = (
  setFormattedPhoneNumber: any,
  phoneToFormat: string
) => {
  const formattedPrimary: string = formatPhoneNumber(phoneToFormat);
  setFormattedPhoneNumber((prev: PhoneNumbers) => ({
    ...prev,
    phone: formattedPrimary,
  }));
};

export {
  convertProvinceCodeToLonghand,
  handleClose,
  provinces,
  clearBillingInformationFromAddresses,
  clearShippingInformationFromAddresses,
  handleBillingShippingUpdate,
  handleDelete,
  handleClickOpen,
  onClose,
  updatePhoneNumbersAddress
};