import React, {useContext, useEffect, useState} from 'react';
import RegistrationLayout from '../layout/registration-layout';
import {
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  SelectChangeEvent
} from '@mui/material';
import TextField from '@mui/material/TextField';
import {address2Validator, cityValidator, postalCodeValidator} from '../../../util/validator';
import {CanadianProvinces, Countries} from '../../../util/enums';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {RegInfoType, RegistrationContext, RegistrationPagesEnum} from '../../../contexts/registration';
import {AddressPageProps} from "./addresses-types";
import {useTranslation} from 'react-i18next';
import {CircularLoading} from '../../../components/loading/circular-loading';
import AddressSearch from '../../../components/canada-post/address-search';
import {useSnackbar} from 'notistack';
import {GlobalContext} from '../../../contexts/global';
import {
  PRIVACY_POLICY_PFG
} from '../../../util/constants';
import { getTermsAndConditionsLink } from "../../../util/helpers";
import {finalSubmit, onSubmit, setFieldsOnMount} from './helpers';
import AlertDialog from '../../../components/cancel-dialogue/cancelDialogue';
import {formatPostalCode} from "../../my-account/addresses/helpers/postalCodeFormatter";
import './addresses.scss';

/**
 * Page to collect address
 * @constructor
 */
const AddressPage = (props: AddressPageProps) => {
  const { addressFields, setAddressFields, theme } = props;
  const { globalContext } = useContext(GlobalContext);
  const { enqueueSnackbar } = useSnackbar();
  const { t, ready } = useTranslation();
  const { registrationContext, setRegistrationContext } = useContext(RegistrationContext);
  const [openDialogue, setOpenDialogue] = useState<boolean>(false);

  useEffect(() => {
    setFieldsOnMount(setAddressFields, addressFields, registrationContext);
  }, []);

  useEffect(() => {
    if (registrationContext.submit) {
      finalSubmit(
        setAddressFields,
        registrationContext,
        setRegistrationContext,
        enqueueSnackbar,
        t,
        theme
      );
    }
  }, [registrationContext.submit]);

  if (!ready || addressFields.submitting) {
    return (
      <RegistrationLayout>
        <div className="loadingSpinnerContainer">
          <CircularLoading theme={theme}/>
        </div>
      </RegistrationLayout>
    )
  }

  return (
    <>
      <AlertDialog
        openDialogue={openDialogue}
        setOpenDialogue={setOpenDialogue}
        setRegistrationContext={setRegistrationContext}
        theme={theme}
      />
      <RegistrationLayout>
        <form
          onSubmit={(event: React.FormEvent<HTMLFormElement>) => onSubmit(
            event,
            addressFields,
            setAddressFields,
            registrationContext,
            setRegistrationContext,
            enqueueSnackbar,
            t
          )}
          className={globalContext.referer === 'choices' ? 'choicesWhiteBoxWide' : ''}
          noValidate>
          <div className="accountWrapper">
            <Typography
              variant="h2"
              component="h2"
              className={theme === "choices" ? "topHeaderChoices" : "topHeader"}
            >{t('REGISTER.HEADERS.CREATE_ACCOUNT')}</Typography>
            <Card className="wide-white-box steps">
              {theme !== 'choices' &&
                <div className="infoBar infoBarThin">
                  <div className="info"><h3><strong>Step 3 / 3:</strong> Your address</h3></div>
                </div>
              }
              <div className="centeredContainer">
                <div className="innerColumn">
                  <Grid
                    container rowSpacing={2}
                    columnSpacing={{ md: 5, lg: 5 }}
                  >
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <InputLabel required className="inputLabel">{t('BASIC.ADDRESS_LINE_1')}</InputLabel>
                      <AddressSearch
                        className="fullWidth"
                        addressLine1={addressFields.addressLine1}
                        setAddressLine1={(newVal: string) => {
                          setAddressFields((prev: any) => ({ ...prev, addressLine1: newVal }));
                        }}
                        addressLine1HelperText={addressFields.addressLine1HelperText}
                        setAddressLine1HelperText={(newVal: string) => {
                          setAddressFields((prev: any) => ({ ...prev, addressLine1HelperText: newVal }));
                        }}
                        setCity={(newVal: string) => {
                          setAddressFields((prev: any) => ({ ...prev, city: newVal }));
                        }}
                        cityHelperText={addressFields.cityHelperText}
                        setCityHelperText={(newVal: string) => {
                          setAddressFields((prev: any) => ({ ...prev, cityHelperText: newVal }));
                        }}
                        setAddressLine2={(newVal: string) => {
                          setAddressFields((prev: any) => ({ ...prev, addressLine2: newVal }));
                        }}
                        setPostalCode={(newVal: string) => {
                          setAddressFields((prev: any) => (
                            { ...prev, postalCode: newVal }
                          ));
                        }}
                        postalCodeHelperText={addressFields.postalCodeHelperText}
                        setPostalCodeHelperText={(newVal: string) => {
                          setAddressFields((prev: any) => ({ ...prev, postalCodeHelperText: newVal }));
                        }}
                        setCountry={(newVal: string) => {
                          setAddressFields((prev: any) => ({ ...prev, country: newVal }));
                        }}
                        setProvince={(newVal: string) => {
                          setAddressFields((prev: any) => ({ ...prev, province: newVal }));
                        }}
                        provinceHelperText={addressFields.provinceHelperText}
                        setProvinceHelperText={(newVal: string) => {
                          setAddressFields((prev: any) => ({ ...prev, provinceHelperText: newVal }));
                        }}
                        tabIndex={1}
                        // autofocus
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <InputLabel className="inputLabel">{t('BASIC.ADDRESS_LINE_2')}</InputLabel>
                      <TextField
                        autoComplete="address-line2"
                        className="fullWidth"
                        InputProps={{ inputProps: { tabIndex: 2 } }}
                        type="text"
                        error={addressFields.addressLine2HelperText !== ''}
                        helperText={addressFields.addressLine2HelperText}
                        value={addressFields.addressLine2}
                        onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                          setAddressFields((prev: any) => ({
                            ...prev,
                            addressLine2: event.target.value,
                            addressLine2HelperText: address2Validator(event.target.value, t)
                          }));
                        }}/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <InputLabel required className="inputLabel">{t('BASIC.CITY')}</InputLabel>
                      <TextField
                        autoComplete="address-level2"
                        className="fullWidth"
                        required
                        InputProps={{ inputProps: { tabIndex: 3 } }}
                        type="text"
                        error={addressFields.cityHelperText !== ''}
                        helperText={addressFields.cityHelperText}
                        value={addressFields.city}
                        onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                          setAddressFields((prev: any) => ({
                            ...prev,
                            city: event.target.value,
                            cityHelperText: cityValidator(event.target.value, t)
                          }));
                        }}/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <InputLabel required className="inputLabel">{t('BASIC.PROVINCE')}</InputLabel>
                      <FormControl
                        className="fullWidth"
                        error={addressFields.provinceHelperText !== ''}>
                        <Select
                          MenuProps={{ className: 'outlineBorder' }}
                          required
                          autoComplete="address-level1"
                          defaultValue=""
                          value={addressFields.province}
                          tabIndex={4}
                          id="province"
                          labelId="province"
                          onChange={(event: SelectChangeEvent) => {
                            setAddressFields((prev: any) => ({
                              ...prev,
                              province: event.target.value
                            }));
                            if (event.target.value.length > 0) {
                              setAddressFields((prev: any) => ({
                                ...prev,
                                provinceHelperText: ''
                              }));
                            }
                          }}>
                          {Object.entries(CanadianProvinces).map(([key, value]) => (
                            <MenuItem key={key} value={key}>{value}</MenuItem>)
                          )}
                        </Select>
                        {addressFields.provinceHelperText !== '' &&
                          <FormHelperText>{addressFields.provinceHelperText}</FormHelperText>}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <InputLabel required className="inputLabel">{t('BASIC.POSTAL_CODE')}</InputLabel>
                      <TextField
                        id="regPostalCode"
                        autoComplete="postal-code"
                        className="fullWidth"
                        required
                        InputProps={{ inputProps: { tabIndex: 5 } }}
                        type="text"
                        error={addressFields.postalCodeHelperText !== ''}
                        helperText={addressFields.postalCodeHelperText}
                        value={addressFields.postalCode}
                        onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                          let input = event.target.value.toUpperCase();
                          if (input.length > 7) {
                            return;
                          }
                          if (input.length === 4 && !(input.at(3) === '-' || input.at(3) === '-')) {
                            input = input.substring(0, 3) + '-' + input.substring(3);
                          }
                          setAddressFields((prev: any) => ({
                            ...prev,
                            postalCode: formatPostalCode(input),
                            postalCodeHelperText: postalCodeValidator(input, t)
                          }));
                        }}/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <InputLabel required className="inputLabel">{t('BASIC.COUNTRY')}</InputLabel>
                      <FormControl className="fullWidth">
                        <Select
                          required
                          autoComplete="country-name"
                          id="country"
                          labelId="country"
                          tabIndex={6}
                          disabled
                          value={addressFields.country}
                          onChange={(event: SelectChangeEvent) => {
                            setAddressFields((prev: any) => ({ ...prev, country: event.target.value }));
                          }}
                        >{Object.entries(Countries).map(([key, value]) =>
                          (<MenuItem key={key} value={key}>{value}</MenuItem>)
                        )}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid className="mobileMargin" item xs={12} sm={12} md={12} lg={12}>
                      <FormControl className="fullWidth">
                        <FormControlLabel
                          id="iDontHaveCardBtn"
                          className="center-flex"
                          label={
                            <div className="readAgree">I have read and agree to the&nbsp;
                              <Link
                                className="link-dark"
                                href={PRIVACY_POLICY_PFG}
                                target="_blank">
                                Privacy Policy
                              </Link> and&nbsp;
                              <Link
                                className="link-dark"
                                href={getTermsAndConditionsLink(theme)}
                                target="_blank">Terms of Use
                              </Link>
                            </div>
                          }
                          control={<Checkbox
                            checked={addressFields.isAgreeTOS}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                              setAddressFields((prev: any) => ({
                                ...prev,
                                isAgreeTOS: event.target.checked
                              }));
                            }}/>}/>
                        {addressFields.isAgreeTOSHelperText && <FormHelperText error style={{
                          'marginLeft': 'auto',
                          'marginRight': 'auto'
                        }}>{addressFields.isAgreeTOSHelperText}</FormHelperText>}
                      </FormControl>
                      {theme === "choices" &&
                        <Typography className="choicesConfirmationText">
                          I understand that Choices Markets shares a common sign in and account management platform with
                          morerewards.ca, saveonfoods.com, urbanfare.com, and pricesmartfoods.com, and my information
                          will be shared for administrative purposes.
                        </Typography>
                      }
                      {theme === "meinhardt" &&
                        <Typography className="choicesConfirmationText">
                          I understand that Meinhardt Fine Foods shares a common sign in and account management platform with
                          morerewards.ca, saveonfoods.com, urbanfare.com, and pricesmartfoods.com, and my information
                          will be shared for administrative purposes.
                        </Typography>
                      }
                    </Grid>
                  </Grid>
                </div>
              </div>
              <div className="account-steps-nav three-button flexCenter borderTop">
                <Button
                  id="nextStepBtn"
                  type="submit"
                  variant="contained"
                  className="primaryButtonDefault createAccountButton">{t('BUTTONS.CREATE_ACCOUNT')}
                </Button>
              </div>
              <div className="account-steps-nav-cancel three-button flexCenter borderTop">
                <Button
                  onClick={() => {
                    setRegistrationContext((prev: RegInfoType) => ({
                      ...prev,
                      currentPage: RegistrationPagesEnum.ValidationPage,
                    }));
                  }}
                  id="nextStepBtn"
                  type="submit"
                  variant="contained"
                  className="cancelButtonDefault">{t('Back')}
                </Button>
                <Button
                  variant="outlined"
                  className="cancelButtonDefault"
                  onClick={() => {
                    setOpenDialogue(true)
                  }}
                >{t('BUTTONS.CANCEL')}</Button>
              </div>
            </Card>
          </div>
        </form>
      </RegistrationLayout>
    </>
  );
};

export default AddressPage;