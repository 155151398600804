import React, {useContext, useEffect, useState} from 'react';
import AlertDialog from "../../../components/cancel-dialogue/cancelDialogue";
import {LINK_CONTACT_US} from "../../../util/constants";
import RegistrationLayout from '../layout/registration-layout';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {
  Card,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel, Link,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent
} from '@mui/material';
import {RegInfoType, RegistrationContext, RegistrationPagesEnum} from '../../../contexts/registration';
import {useTranslation} from 'react-i18next';
import {
  autoFillFormInputs,
  securityQuestions,
  SecurityQuestionsResponseType, Store,
  validateSecurityQuestions
} from '../../../network-requests/register';
import {CircularLoading} from '../../../components/loading/circular-loading';
import {useSnackbar} from 'notistack';
import {titleCase} from '../../../util/string-util';
import pushRouteChangeEvent, {pushSpecificEvent} from '../../../util/google-tag-manager';
import {formatPostalCode} from "../../my-account/addresses/helpers/postalCodeFormatter";

// Properly handle third party stores/vendors (Panago Pizza, Pure Pharmacy, Urban Barn, etc)
export const handleThirdPartyStoreIDs = async (rResults: SecurityQuestionsResponseType) => {
  try {
    if (!rResults.stores) return rResults; // Catch when there are no stores in list
    let clonedStoresArr: Store[] = JSON.parse(JSON.stringify(rResults.stores));
    for (let store of clonedStoresArr) {
      if (store.storeID === 0) store.storeID = store.vendorCode;
    }
    rResults.stores = clonedStoresArr;
    return rResults;
  } catch (e) {
    console.error(e);
  }
}

export type SecurityQuestionsPageProps = {
  theme: string
}

/**
 * Security questions show if user wants to register with an existing card
 * @constructor
 */
const SecurityQuestionsPage = (props: SecurityQuestionsPageProps) => {
  const { theme } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { t, ready } = useTranslation();
  const [results, setResults] = useState<SecurityQuestionsResponseType | undefined>();
  const { registrationContext, setRegistrationContext } = useContext(RegistrationContext);
  const [startUpDone, setStartUpDone] = useState<boolean>(false);
  const [store, setStore] = useState<string>('');
  const [storeHelperText, setStoreHelperText] = useState<string>('');
  const [fName, setFName] = useState<string>('');
  const [fNameHelperText, setFNameHelperText] = useState<string>('');
  const [lName, setLName] = useState<string>('');
  const [lNameHelperText, setLNameHelperText] = useState<string>('');
  const [postalCode, setPostalCode] = useState<string>('');
  const [postalCodeHelperText, setPostalCodeHelperText] = useState<string>('');
  const [requestSent, setRequestSent] = useState<boolean>(false);
  const [openDialogue, setOpenDialogue] = useState<boolean>(false);

  /* Get the More Rewards Card number from context. If not there, route to contact customer service
   * (user should not see this page if they did not set a mrCard). Else get questions from microservice */
  useEffect(() => {
    const startUp = async () => {
      await securityQuestions(registrationContext.jwt).then(async (response: Response) => {
        // If non 200 response route to contact customer service
        if (response.status !== 200) {
          pushSpecificEvent("Register: SecurityQuestionsPage - FAILED");
          response.text().then(msg => enqueueSnackbar(msg, { variant: 'error' }));
          setRegistrationContext((prev: RegInfoType) => ({
            ...prev,
            currentPage: RegistrationPagesEnum.ContactCustomerServicePage
          }));
        } else {
          let rResults: SecurityQuestionsResponseType = await response.json();
          // Unused card
          if (!rResults.lastNames && !rResults.firstNames && !rResults.stores && !rResults.postalCodes) {
            await submitQuestions();
            return;
          }
          setResults(await handleThirdPartyStoreIDs(rResults));
        }
      }).catch(e => {
        enqueueSnackbar(t('ERRORS.API_OFFLINE'), { variant: 'error' });
        setRegistrationContext((prev: RegInfoType) => ({
          ...prev,
          currentPage: RegistrationPagesEnum.ContactCustomerServicePage
        }));
      }).finally(() => {
        setStartUpDone(true);
      });
    };
    startUp();
    pushRouteChangeEvent('Register: SecurityQuestionsPage');
  }, []);

  async function submitQuestions() {
    try {
      let response: Response = await validateSecurityQuestions(
        registrationContext.jwt,
        fName,
        lName,
        postalCode,
        store.toString()
      );
      if (response.status !== 200) {
        pushSpecificEvent("Register: SecurityQuestionsPage - FAILED");
        response.text().then(msg => enqueueSnackbar(msg, { variant: 'error', autoHideDuration: 15000 }));
        return;
      } else {
        let validateCardResponse = await response.json();
        await autoFillFormInputs(validateCardResponse.jwt, enqueueSnackbar, setRegistrationContext, t);
        setRegistrationContext((prev: RegInfoType) => ({
          ...prev,
          jwt: validateCardResponse.jwt,
          currentPage: RegistrationPagesEnum.ValidationPage
        }));
      }
      // Catch error if api down
    } catch (e) {
      enqueueSnackbar(t('ERRORS.API_OFFLINE'), { variant: 'error' });
      return;
    }
  }

  async function handleSubmit() {
    let inputError: boolean = false;
    if (results.firstNames && fName.length === 0) {
      setFNameHelperText(t('REGISTER.SECURITY_QUESTIONS.FIRST_NAME_REQUIRED'));
      inputError = true;
    }
    if (results.lastNames && lName.length === 0) {
      setLNameHelperText(t('REGISTER.SECURITY_QUESTIONS.LAST_NAME_REQUIRED'));
      inputError = true;
    }
    if (results.postalCodes && postalCode.length === 0) {
      setPostalCodeHelperText(t('REGISTER.SECURITY_QUESTIONS.POSTAL_CODE_REQUIRED'));
      inputError = true;
    }
    if (results.stores && store.length === 0) {
      setStoreHelperText(t('REGISTER.SECURITY_QUESTIONS.LAST_STORE_VISITED_REQUIRED'));
      inputError = true;
    }
    if (inputError) {
      return;
    }
    setRequestSent(true);
    await submitQuestions();
  }

  if (!ready || !startUpDone) {
    return <RegistrationLayout><CircularLoading theme={theme}/></RegistrationLayout>;
  }

  return (
    <>
      <AlertDialog
        openDialogue={openDialogue}
        setOpenDialogue={setOpenDialogue}
        setRegistrationContext={setRegistrationContext}
        theme={theme}
      />
      <RegistrationLayout>
        {results && <div>
          <Typography variant="h2" component="h2" className="topHeader">
            {t('REGISTER.HEADERS.SECURITY_QUESTIONS')}
          </Typography>
          <Typography
            component="p"
            variant="body2"
            className="topTextRegister">
            {t('REGISTER.SECURITY_QUESTIONS.DESC')}
          </Typography>
          <div className="accountWrapper">
            <Card className="wide-white-box">
              <div className="centeredContainer">
                <div className="innerColumn">
                  <form noValidate>
                    <Grid container columnSpacing={{ md: 4, lg: 4 }}>
                      {results.firstNames &&
                        <Grid item xs={12} sm={12} md={6} lg={6} className="marginT24">
                          <FormControl error={fNameHelperText.length !== 0}>
                            <FormLabel
                              id="fNameRadio"
                              required>{t('REGISTER.SECURITY_QUESTIONS.FIRST_NAME_Q')}
                            </FormLabel>
                            <RadioGroup
                              aria-labelledby="fNameRadio"
                              name="fNameRadio"
                              onChange={(event, value: string) => {
                                setFName(value);
                                setFNameHelperText('');
                                setRequestSent(false);
                              }}
                            >
                              <FormHelperText>{fNameHelperText}</FormHelperText>
                              {results.firstNames.map((fName: string) => (
                                <FormControlLabel
                                  key={fName}
                                  value={fName}
                                  control={<Radio/>}
                                  label={titleCase(fName)}/>
                              ))}
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                      }
                      {results.lastNames &&
                        <Grid item xs={12} sm={12} md={6} lg={6} className="marginT24">
                          <FormControl error={lNameHelperText.length !== 0}>
                            <FormLabel
                              id="lNameRadio"
                              required>{t('REGISTER.SECURITY_QUESTIONS.LAST_NAME_Q')}
                            </FormLabel>
                            <RadioGroup
                              aria-labelledby="lNameRadio"
                              name="lNameRadio"
                              onChange={(event, value: string) => {
                                setLName(value);
                                setLNameHelperText('');
                                setRequestSent(false);
                              }}
                            >
                              <FormHelperText>{lNameHelperText}</FormHelperText>
                              {results.lastNames.map((lName: string) => (
                                <FormControlLabel
                                  key={lName}
                                  value={lName}
                                  control={<Radio/>}
                                  label={titleCase(lName)}
                                />
                              ))}
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                      }
                      {results.postalCodes &&
                        <Grid item xs={12} sm={12} md={6} lg={6} className="marginT24">
                          <FormControl error={postalCodeHelperText.length !== 0}>
                            <FormLabel
                              id="postalCodeRadio"
                              required>{t('REGISTER.SECURITY_QUESTIONS.POSTAL_CODE_Q')}
                            </FormLabel>
                            <RadioGroup
                              aria-labelledby="postalCodeRadio"
                              name="postalCodeRadio"
                              onChange={(event, value: string) => {
                                setPostalCode(formatPostalCode(value));
                                setPostalCodeHelperText('');
                                setRequestSent(false);
                              }}
                            >
                              <FormHelperText>{postalCodeHelperText}</FormHelperText>
                              {results.postalCodes.map((postalCode: string) => (
                                <FormControlLabel
                                  key={postalCode}
                                  value={(postalCode)}
                                  control={<Radio/>}
                                  label={formatPostalCode(postalCode)}
                                />
                              ))}
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                      }
                      {results.stores &&
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <Typography component="p" variant="body2" id="lastStoreParagraph">
                            <b>{t('REGISTER.SECURITY_QUESTIONS.LAST_STORE_VISITED_Q')}</b>
                            <br/>
                            For security purposes we need to confirm the store where the cardholder last completed a purchase.
                            If you have any questions please&nbsp;
                            <Link
                              href={LINK_CONTACT_US}
                              target="_blank">contact us
                            </Link>
                          </Typography>
                          <InputLabel required className="inputLabel inputLabelWhite">{t('BASIC.STORE')}</InputLabel>
                          <FormControl className="fullWidth" error={storeHelperText.length !== 0}>
                            <Select
                              required
                              label={t('BASIC.STORE')} // Don't know why but this is required
                              labelId="store"
                              value={store}
                              onChange={(event: SelectChangeEvent<string>) => {
                                setStore(event.target.value);
                                setStoreHelperText('');
                                setRequestSent(false);
                              }}
                            >
                              {results.stores.map((store: Store) => (
                                <MenuItem key={store.storeID} value={store.storeID}>{store.locationDesc}</MenuItem>
                              ))}
                            </Select>
                            <FormHelperText>{storeHelperText}</FormHelperText>
                          </FormControl>
                        </Grid>
                      }
                    </Grid>
                  </form>
                </div>
              </div>
              <div className="centeredContainer account-steps-nav-cancel borderTop">
                <Button
                  onClick={handleSubmit}
                  disabled={requestSent}
                  id="nextStepBtn"
                  type="submit"
                  variant="contained"
                  className="primaryButtonDefault"
                >{t('BUTTONS.VERIFY')}</Button>
                <Button
                  variant="outlined"
                  className="cancelButtonDefault"
                  onClick={() => {setOpenDialogue(true);}}
                >{t('BUTTONS.CANCEL')}</Button>
              </div>
            </Card>
          </div>
        </div>}
      </RegistrationLayout>
    </>
  );
};

export default SecurityQuestionsPage;