import { getContactUsLink } from '../../../util/helpers';
import {Link, Typography} from "@mui/material";

export const displayLoginFailureError = (enqueueSnackbar: any, theme: string): any => {
  return enqueueSnackbar(
    <>
      <Typography
        sx={{
          marginRight: '5px',
          marginLeft: '5px',
          cursor: 'pointer'
        }}
      >
        Error when logging in. If this issue persists please&nbsp;
        <Link
          href={getContactUsLink(theme)}
          target="_blank"
          className="link-white"
        > contact us
        </Link>.
      </Typography>
    </>,
    { variant: 'error' }
  )
}