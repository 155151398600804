import React, {useContext, useEffect, useState} from 'react';
import PageFooter from '../../components/footer/footer';
import CssBaseline from '@mui/material/CssBaseline';
import {useNavigate} from 'react-router-dom';
import {
  Button,
  Grid,
} from '@mui/material';
import {calculateRecaptchaOnMount, getRecaptchaV2} from './recaptcha/recaptcha';
import OneTimePassPage from './one-time-pass';
import {useTranslation} from 'react-i18next';
import {useSnackbar} from 'notistack';
import {
  fetchAccessToken,
  resetBiometricState
} from '../../network-requests/authentication';
import {
  validationOnSubmit,
  verifyV3RecaptchaScore,
  showQaNotificationIfApplicable,
  handleSessionTimeout,
  initialLoad,
  pageMount,
  handleKeyPress,
  submitButton,
  initializeSpEntityId,
  changeBiometricLoginState,
} from './helpers/helpers';
import {GoogleGraphQLData} from '../../network-requests/resources';
import {loginProps} from '../../App';
import {GlobalContext} from '../../contexts/global';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import {visuallyHidden} from '@mui/utils';
import {
  EMAIL_REGEX,
  LINK_CONTACT_US, LOGIN,
  REGISTER_BIOMETRICS_DEVICE,
  REGISTER_BIOMETRICS_DEVICE_IOS,
  SIGN_IN_BIOMETRICS,
  SIGN_IN_BIOMETRICS_IOS
} from '../../util/constants';
import { getTermsAndConditionsLink } from "../../util/helpers";
import {RegistrationContext} from "../../contexts/registration";
import CardWithLogo from '../../components/card/card-with-logo';
import {LoginStateType} from "./loginTypes";
import PasswordInput from "../../components/password-input/password-input";
import EmailInput from "../../components/email-input/email-input";
import TermsAndConditions from "../../components/terms-and-conditons/terms-and-conditions";
import LoginExplanation from "../../components/login-explanation/login-explanation";
import BrandLogoComponent from "../../components/brand-logo-component/brand-logo-component";
import NeedHelpComponent from "../../components/need-help-component/need-help";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import './login.css';

// Test for GH actions
export default function SharedLoginPage(props: loginProps) {

  // All other hooks and functions are defined below
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { globalContext, setGlobalContext } = useContext(GlobalContext);
  const { setRegistrationContext } = useContext(RegistrationContext); // For resetting a users password in notification
  const termsLink = getTermsAndConditionsLink(globalContext.referer);
  const {
    isAuth,
    errorLogin,
    setErrorLogin,
    setIsAuth,
    theme,
    goto
  } = props; // Get goto from App Component props, which is preserved across login & reg pages

  // Form state for our login page. Basically a finite state machine to handle the login flow for Oauth 2.0, Biometrics and OTP
  const [loginState, setLoginState] = useState<LoginStateType>({
    spEntityId: '',
    password: '',
    loading: false,
    showPassword: false,
    loginFlowSuccessful: false,
    rememberMe: false,
    recaptchaFlowFailedOnMount: false,
    recaptchaV2Response: {
      success: null,
      challenge_ts: null,
      hostname: null
    },
    recaptchaScore: {
      success: null,
      challenge_ts: null,
      hostname: null,
      score: null,
      action: null
    },
    OTPState: {
      OTPLoading: false,
      resendButtonLoading: false,
      displayOTP: false
    },
    biometricsState: {
      enabled: false,
      hasPasswordCallbackDuringRegistration: false,
      submitting: false,
      showNewDeviceConfirmation: false,
      passwordVerified: false,
      hideBiometric: false,
    }
  });

  /* Set V2 recaptcha response here. we have to use the setRecaptchaV2Response setter in here for it to work
   * TODO: refactor this later to be in the helpers.tsx file for this component */
  const verifyCallback = async (V2token: string) => {
    if (V2token) {
      const response: GoogleGraphQLData = await getRecaptchaV2(V2token);
      setLoginState((prev: LoginStateType) => ({
        ...prev,
        recaptchaV2Response: {
          ...prev.recaptchaV2Response,
          challenge_ts: response.challenge_ts,
          success: response.success,
          hostname: response.hostname
        }
      }));
    }
  };

  // Kick off initial OAuth step + calculate the recaptcha score as the component is mounted
  useEffect(() => {
    pageMount(
      globalContext,
      setGlobalContext,
      isAuth,
      enqueueSnackbar,
      setLoginState,
      setErrorLogin,
      loginState
    ).then(() => {
      if (localStorage.getItem('userLastSignedInWithBiometric') !== null && localStorage.getItem('userLastSignedInWithBiometric').match(EMAIL_REGEX)) {
        changeBiometricLoginState(loginState, setLoginState, true);
        setLoginState((prev: LoginStateType) => ({ ...prev, loginFlowSuccessful: true, }));
      }
    })
  }, []);

  // Set the correct SP entity ID to break SSO for a choices account when the login page mounts
  useEffect(() => {
    initializeSpEntityId(setLoginState, theme);
    if (theme) {
      showQaNotificationIfApplicable(enqueueSnackbar, theme);
    }
  }, [theme])

  // Asynchronously check if the recaptcha on the login flow after clicking the login button worked or not
  useEffect(() => {
    if (loginState.loginFlowSuccessful) {
      validationOnSubmit(
        globalContext,
        setGlobalContext,
        enqueueSnackbar,
        navigate,
        setIsAuth,
        isAuth,
        setErrorLogin,
        closeSnackbar,
        setRegistrationContext,
        t,
        theme,
        loginState,
        setLoginState
      );
    }
  }, [loginState.loginFlowSuccessful]);

  // Handles Recaptcha V2 on initial mount and after clicking the login button.
  useEffect(() => {
    verifyV3RecaptchaScore(
      globalContext,
      setGlobalContext,
      enqueueSnackbar,
      verifyCallback,
      navigate,
      setIsAuth,
      isAuth,
      setErrorLogin,
      closeSnackbar,
      setRegistrationContext,
      t,
      theme,
      loginState,
      setLoginState
    );
  }, [loginState.recaptchaScore, loginState.recaptchaFlowFailedOnMount]);

  // Handle when a users SSO session times out or the oAuth step is incorrect at the start of the Oauth flow
  useEffect(() => {
    if (globalContext.sessionTimedOut) {
      handleSessionTimeout(
        globalContext,
        setGlobalContext,
        loginState,
        setLoginState,
        isAuth,
        setIsAuth,
        setErrorLogin,
        setRegistrationContext,
        t,
        theme,
        navigate,
        closeSnackbar,
        enqueueSnackbar
      )
    }
    calculateRecaptchaOnMount(
      globalContext,
      setGlobalContext,
      isAuth,
      enqueueSnackbar,
      loginState,
      setLoginState
    );
  }, [globalContext.oAuthStep]);

  // Handle access token exchange in OAuth 2.0 flow
  useEffect(() => {
    if (globalContext.SSOAuthenticated && globalContext.oAuthStep?.type === 'LoginSuccess') {
      fetchAccessToken(
        setGlobalContext,
        navigate,
        globalContext,
        enqueueSnackbar,
        setIsAuth,
        isAuth,
        setErrorLogin,
        goto,
        theme,
        setLoginState
      );
    }
  }, [globalContext.SSOAuthenticated]);

  if (loginState.OTPState.displayOTP) {
    return (
      <OneTimePassPage
        revert={initialLoad}
        theme={theme}
        setLoginState={setLoginState}
        loginState={loginState}
      />
    );
  }

  return (
    <>
      <div>
        {errorLogin
          ?
          <div className="centeredContainerError">
            <CardWithLogo children={null} displayBannerLogo={false} globalContext={globalContext}/>
            <div className="loginErrorMessage">
              <Typography className={
                theme === "choices" ? "errorTopHeaderChoices marginB40" : "errorTopHeader marginB40"
              }> {errorLogin}. If you have any trouble,
                please <a href={LINK_CONTACT_US} target="_blank" className={
                  theme === "choices" ? "link-choices underline" : "link-white underline"
                }>contact us</a>.
              </Typography>
            </div>
          </div>
          :
          <div className="centeredChoicesLogin">
            <div className="innerColumn-login relative">
              <Grid container rowSpacing={2}>
                {globalContext.isWebview && (
                  globalContext.referer === 'morerewards' || globalContext.referer === 'myoffers'
                ) ?
                  <></>
                  :
                  <BrandLogoComponent referer={globalContext.referer}/>
                }
                <Grid item xs={12} sm={12} md={5} lg={5}>
                  <Card className="loginCard">
                    <CardContent>
                      <Container component="main">
                        <CssBaseline/>
                        <Box className="loginBox">
                          <>
                            <Box className="login-form noBG-form">
                              {globalContext.referer !== 'choices' && globalContext.referer !== 'meinhardt' ?
                                <img
                                  className={
                                    globalContext.isWebview &&
                                    !['choices', 'urbanfare', 'saveonfoods', 'pricesmartfoods'].includes(theme) ?
                                      "moreRewardsIDLogoMobileWebView" :
                                      "moreRewardsIDLogo"
                                  }
                                  src={require('../../images/global-logos/mr-id.png')}
                                  alt="More Rewards ID"
                                />
                                :
                                <></>
                              }
                              {( // Display a prompt asking the user to confirm their password for a new biometrics device registration
                                loginState.biometricsState.hasPasswordCallbackDuringRegistration &&
                                !loginState.biometricsState.showNewDeviceConfirmation
                              ) && (
                                <>
                                  <Typography component="p" className="small-text biometricsFont">
                                    Your fingerprint or Face ID is not registered on this device. {!loginState.biometricsState.passwordVerified?<span> To enable fingerprint or Face ID, enter your password below.</span>:<></>} <Link className={'link-white'} href={termsLink} target="_blank">
                                    Terms of Use
                                  </Link><br/><br/>

                                  </Typography>
                                  {!loginState.biometricsState.passwordVerified ?
                                  <PasswordInput
                                    loginState={loginState}
                                    setLoginState={setLoginState}
                                    theme={theme}
                                    handleKeyPress={handleKeyPress}
                                    navigate={navigate}
                                    biometricPrompt={false}
                                    globalContext={globalContext}
                                    errorLogin={errorLogin}
                                    enqueueSnackbar={enqueueSnackbar}
                                  />:<></>}
                                  {loginState.loading ?
                                    <LoadingButton
                                      loading
                                      className={"primaryButtonDefault sign-in-button enableFaceId"}
                                      fullWidth
                                      loadingPosition="start"
                                      sx={{ mt: 3, mb: 2 }}
                                      startIcon={<SaveIcon/>}
                                      variant="outlined"
                                    >
                                      Please wait..
                                    </LoadingButton>
                                    :
                                    <Button
                                      disabled={errorLogin}
                                      type="submit"
                                      className="enableFaceId primaryButtonDefault hideFromDesktop sign-in-button g-recaptcha"
                                      fullWidth
                                      variant="contained"
                                      sx={{ mt: 3, mb: 2 }}
                                      onClick={() => validationOnSubmit(
                                        globalContext,
                                        setGlobalContext,
                                        enqueueSnackbar,
                                        navigate,
                                        setIsAuth,
                                        isAuth,
                                        setErrorLogin,
                                        closeSnackbar,
                                        setRegistrationContext,
                                        t,
                                        theme,
                                        loginState,
                                        setLoginState
                                      )}
                                    >
                                      {globalContext.isIos?REGISTER_BIOMETRICS_DEVICE_IOS:REGISTER_BIOMETRICS_DEVICE}
                                    </Button>
                                  }
                                  <div className="links-change-password">
                                    <Link
                                      className="enableFaceId link-yellow link-choices"
                                      onClick={() => resetBiometricState(
                                        globalContext,
                                        loginState,
                                        setLoginState,
                                        setGlobalContext,
                                        enqueueSnackbar,
                                        null
                                      )}
                                      style={{ fontSize: '16px' }} // Adjust the font size as needed
                                    >
                                      Sign in with password
                                    </Link>
                                  </div>
                                </>
                              )}
                              {(
                                !loginState.biometricsState.hasPasswordCallbackDuringRegistration
                              ) && (
                                <>
                                  <EmailInput visuallyHidden={visuallyHidden}/>
                                  <PasswordInput
                                    loginState={loginState}
                                    setLoginState={setLoginState}
                                    theme={theme}
                                    handleKeyPress={handleKeyPress}
                                    navigate={navigate}
                                    biometricPrompt={true}
                                    globalContext={globalContext}
                                    errorLogin={errorLogin}
                                    enqueueSnackbar={enqueueSnackbar}
                                  />
                                  <div id="html_element" className="recaptha"/>
                                  <div className="loginButtons">
                                    {
                                      loginState.loading ||
                                    (
                                      // !loginState.recaptchaScore.success ||
                                      !globalContext.oAuthStep
                                    )
                                      ?
                                      <LoadingButton
                                        loading
                                        disabled={
                                          // !loginState.recaptchaScore.success ||
                                          !globalContext.oAuthStep
                                        }
                                        className={
                                          loginState.biometricsState.enabled ?
                                            "biometricButtonDefault sign-in-button"
                                            :
                                            "primaryButtonDefault sign-in-button"
                                        }
                                        fullWidth
                                        loadingPosition="start"
                                        sx={{ mt: 3, mb: 2 }}
                                        startIcon={<SaveIcon/>}
                                        variant="outlined"
                                      >

                                        {(
                                          // !loginState.recaptchaScore.success ||
                                          !globalContext.oAuthStep
                                        )
                                          ?
                                          "Please wait..."
                                          :
                                          "Signing you in..."
                                        }
                                      </LoadingButton>
                                      :
                                      <div className="login-page-buttons">
                                        <Button
                                          disabled={errorLogin}
                                          type="submit"
                                          className="primaryButtonDefault sign-in-button g-recaptcha"
                                          fullWidth
                                          variant="contained"
                                          sx={{ mt: 3, mb: 2 }}
                                          onClick={() => submitButton(
                                            loginState,
                                            enqueueSnackbar,
                                            setLoginState,
                                            globalContext,
                                            LOGIN,
                                            setGlobalContext,
                                            setErrorLogin
                                          )}
                                        >
                                          {'Sign in'}
                                        </Button>
                                        {
                                          (loginState.biometricsState.hideBiometric === true && globalContext.isIos === true) ?
                                            <></> :
                                            <><div className="hideFromDesktop" style={{textAlign:'center'}}><p>OR</p></div>
                                        <Button
                                          disabled={errorLogin}
                                          type="submit"
                                          className="primaryButtonDefault sign-in-button hideFromDesktop"
                                          fullWidth
                                          variant="contained"
                                          sx={{ mt: 3, mb: 2 }}
                                          endIcon={ // Use the endIcon prop
                                            <FingerprintIcon
                                              className="biometric-fingerprint"
                                              style={{ fontSize: '35px' }}
                                            />
                                          }
                                          onClick={() => submitButton(
                                            loginState,
                                            enqueueSnackbar,
                                            setLoginState,
                                            globalContext,
                                            SIGN_IN_BIOMETRICS,
                                            setGlobalContext,
                                            setErrorLogin
                                          )}
                                        >
                                          {globalContext.isIos?SIGN_IN_BIOMETRICS_IOS:SIGN_IN_BIOMETRICS}
                                        </Button></>
                                        }
                                      </div>
                                    }
                                  </div>
                                  <div className={"marginT24"}></div>
                                  {globalContext.isWebview && globalContext.referer === "morerewards" ?
                                      <></>
                                      :
                                      <TermsAndConditions theme={theme}/>
                                  }
                                  {globalContext.isWebview ?
                                    <></>
                                    :
                                    <NeedHelpComponent theme={theme}/>
                                  }
                                  <div className="registerLink">
                                    {theme === "choices"
                                      ?
                                      <>Don't have an account yet?</>
                                      :
                                      <>Not a member?</>
                                    }
                                    <Link className="link-yellow link-choices" onClick={() => navigate('/register')}>
                                      Register here
                                    </Link>
                                  </div>
                                </>
                              )}
                              {(globalContext.isWebview && globalContext.referer === "morerewards") || (loginState.biometricsState.hasPasswordCallbackDuringRegistration &&
                              !loginState.biometricsState.showNewDeviceConfirmation) ?
                                <></>
                                :
                                <LoginExplanation theme={theme} isWebview={globalContext.isWebview}/>
                              }
                            </Box>
                          </>
                        </Box>
                      </Container>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </div>
          </div>
        }
      </div>
      <PageFooter isLogin={true} theme={theme}/>
    </>
  );
}