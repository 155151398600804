import React, {useEffect, useState} from 'react';
import {CircularProgress, Grid, Link, List} from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import ListItem from '@mui/material/ListItem';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {useNavigate} from "react-router-dom";
import {profileInfoAtom} from '../../../jotai-state-management/profile-global-state';
import {LINK_CONTACT_US, LINK_FAQ} from "../../../util/constants";
import {LinkedAccountProps} from '../myAccountTypes';
import {LinkedCustomer} from '../../../contexts/types/myAccount';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import pushRouteChangeEvent from '../../../util/google-tag-manager';
import {useAtom} from 'jotai';
import './linkedAccounts.scss';

export default function LinkedAccounts(props: LinkedAccountProps) {
  const navigate = useNavigate();
  const [secondary] = useState(false);
  const { loading, theme, setSelectedTab } = props;
  const [profileInfo] = useAtom(profileInfoAtom);

  useEffect(() => {
    if (theme === 'choices' || theme === 'meinhardt') {
      setSelectedTab(0);
      navigate('/userPage/home');
    }
    pushRouteChangeEvent('Profile: LinkedAccounts');
  }, []);

  return (
    <div>
      <Box>
        <Paper elevation={0}>
          {loading
            ?
            <div className="loadingProfile">
              <Box>
                <CircularProgress className="circularProgressSpinner"/>
              </Box>
            </div>
            :
            <>
              <div className="infoBar infoBarThin">
                <div className="info"><h3>Your Linked Cards</h3></div>
              </div>
              <div className="linkedAccounts">
                <div className="centeredContainer">
                  <div className="innerColumn">
                    {profileInfo.linkedAccounts && profileInfo.linkedAccounts.length > 0
                      ?
                      <Grid
                        container rowSpacing={2}
                        columnSpacing={{ md: 5, lg: 5 }}
                      >
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <h3 className="linkedh3">You are linked to these members’ cards.</h3>
                          <List className="linkedAccountsList">
                            {profileInfo.linkedAccounts.map((item: LinkedCustomer) => (
                              <ListItem>
                                <ListItemIcon>
                                  <AccountCircleIcon/>
                                </ListItemIcon>
                                <ListItemText
                                  primary={item.firstName + ' ' + item.lastName}
                                  secondary={secondary ? 'Secondary text' : null}
                                />
                              </ListItem>
                            ))}
                          </List>
                        </Grid>
                        <Grid className="linkedRightCol" item xs={12} sm={12} md={6} lg={6}>
                          <div className="accountsLinkingwrap">
                            <p>Check out our&nbsp;
                              <Link
                                href={LINK_FAQ}
                                target="_blank"
                                className="link-blue">FAQ page
                              </Link> to learn more about linking your cards.
                            </p>
                            <p>To link or unlink cards please&nbsp;
                              <Link
                                href={LINK_CONTACT_US}
                                target="_blank"
                                className="link-blue">
                                contact us
                              </Link>.
                            </p>
                          </div>
                        </Grid>
                      </Grid>
                      :
                      <>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <h3 className="linkedh3">There are no More Rewards Cards currently linked to your
                            account.</h3>
                          <div className="accountsLinkingwrap">
                            <p>Check out our&nbsp;
                              <Link
                                href={LINK_FAQ} target="_blank"
                                className="link-blue">FAQ page
                              </Link> to learn more about linking your cards.
                            </p>
                            <p>To link or unlink cards please&nbsp;
                              <Link
                                href={LINK_CONTACT_US}
                                target="_blank" className="link-blue">contact us
                              </Link>
                            </p>
                          </div>
                        </Grid>
                      </>
                    }
                  </div>
                </div>
              </div>
            </>
          }
        </Paper>
      </Box>
    </div>
  );
}