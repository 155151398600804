import React, {useContext, useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import {useNavigate} from "react-router-dom";
import {profileInfoAtom} from '../../../jotai-state-management/profile-global-state';
import {ProfileProps} from '../myAccountTypes';
import {Button, Link} from '@mui/material';
import {ProfileTextFields} from './textFields';
import {useAtom} from 'jotai';
import {appleWallet, googlePay} from '../../../network-requests/resources';
import pushRouteChangeEvent, {pushSpecificEvent} from '../../../util/google-tag-manager';
import {LINK_CONTACT_US} from "../../../util/constants";
import {GlobalContext} from "../../../contexts/global";
import './profile.css';

export type PhoneNumbers = {
  primary: string,
  alternate1?: string,
  alternate2?: string
}

export type TextFieldProps = {
  theme: string
}

export const Profile = (props: ProfileProps): JSX.Element => {
  const { theme } = props;
  const navigate = useNavigate();
  const { globalContext } = useContext(GlobalContext);
  const [profileInfo] = useAtom(profileInfoAtom);
  const [displayGooglePay] = useState(navigator.userAgent.indexOf('CriOS') !== -1 || navigator.userAgent.indexOf('Chrome') !== -1);
  const [displayApplePay] = useState(!(navigator.userAgent.indexOf('CriOS') !== -1 || navigator.userAgent.indexOf('Chrome') !== -1) && navigator.userAgent.indexOf('Safari') !== -1);

  // only report data to google tag manager when you travel to this route with a valid loaded profile
  useEffect(() => {
    if (profileInfo.email) {
      pushRouteChangeEvent('Profile: Home');
    }
  }, []);

  const handleGooglePayClick = async (mrNumber: string) => {
    try {
      let wallet: Response = await googlePay(mrNumber);
      if (wallet.status != 200) {
        console.error('Error in generating google pay', wallet.text());
        return;
      }
      let jwt: string = await wallet.json();
      window.open(`https://pay.google.com/gp/v/save/${jwt}`, '_blank');
      pushSpecificEvent('Add to Google wallet');
    } catch (e) {
      console.error('Error Downloading Google Wallet', e);
    }
  };

  const appleWalletDownload = async (mrNumber: string) => {
    try {
      let wallet: Response = await appleWallet(mrNumber);
      if (wallet.status !== 200) {
        console.error('Error in downloading apple wallet', wallet.text());
        return;
      }
      let walletJson: any = await wallet.json();
      let element: HTMLAnchorElement = document.createElement('a');
      element.setAttribute(
        'href',
        'data:application/vnd.apple.pkpass;base64,' +
        encodeURIComponent(walletJson.appleFile)
      );
      element.setAttribute('download', 'apple-wallet.pkpass');
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
      pushSpecificEvent('Add to Apple wallet');
    } catch (e) {
      console.error('Error Downloading Apple Wallet', e);
    }
  };

  return (
    <>
      <div>
        <Box
        >
          <Paper elevation={0}>
            {globalContext.isWebview && theme === 'morerewards'
              ?
              <></>
              :
              <>
                {theme === 'choices' || theme === 'meinhardt' ?
                  <div className="infoBar infoBarThin">
                    <div className="info"><h3>Profile</h3></div>
                  </div>
                  :
                  <>
                    <div className="infoBar infoBarMobile">
                      <div className="justifyContent">
                        <div className="info">
                          <h3>Card Number</h3>
                          <span className="largerText fontweightNormal">{profileInfo.moreRewardsCard}</span>
                        </div>
                        {profileInfo.points !== 'Could not retrieve your points balance. Please refresh the ' +
                        'page and try again! If this issue continues call customer service at 1-800-242-9229'
                          ?
                          <div className="info">
                            <h3>You have</h3>
                            <span
                              className="largerText">
                              {profileInfo.points} {
                              profileInfo.points ||
                              // @ts-ignore
                              profileInfo.points === 0 ? 'pts' : ''
                            }
                            </span>
                          </div>
                          :
                          <div className="info"><span className="smallerText">{profileInfo.points}</span></div>
                        }</div>
                      <div>
                        <p>
                          Your AC# can be found on the back of your card, on your Google or Apple wallets, or sent to
                          your email address from the My Card tab.&nbsp;
                          <Link
                            href={LINK_CONTACT_US}
                            target="_blank"
                            className="link-tamewhite link-choices">
                            Contact us
                          </Link>&nbsp;with any questions.
                        </p>
                      </div>
                    </div>
                  </>
                }
              </>
            }
            <div className="centeredContainer">
              <ProfileTextFields theme={theme}/>
              {globalContext.isWebview || globalContext.referer === 'choices' || theme === 'meinhardt' ?
                <></>
                :
                <div className="walletButtons">
                  {displayApplePay &&
                    <a onClick={() => appleWalletDownload(profileInfo.moreRewardsCard)} className="addToPayButton">
                      <img
                        src={require('../../../images/apple-wallet-button-two-line-large_2x.png')}
                        alt="Add to Apple Wallet"
                        height={55}
                      />
                    </a>
                  }
                  {displayGooglePay &&
                    <a onClick={() => handleGooglePayClick(profileInfo.moreRewardsCard)} className="addToPayButton">
                      <img
                        className="addToPayImage"
                        src={require('../../../images/enCA_add_to_google_wallet_add-wallet-badge.png')}
                        alt="Add to Google Wallet"
                      />
                    </a>
                  }
                </div>
              }
              <div className="centeredContainer" style={{ margin: '1em', justifyContent: 'right', marginBottom: '3em' }}>
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => navigate('/userPage/deleteAccount')}
                  className="deleteButton"
                >
                  Delete
                </Button>
              </div>
            </div>
          </Paper>
        </Box>
      </div>
    </>
  );
};