import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import React, {ChangeEvent, SyntheticEvent, useContext, useEffect, useMemo, useState} from 'react';
import {ProfileInfoType} from "../../../contexts/types/myAccount";
import {trimDoubleSpace} from "../../../util/string-util";
import {
  dobValidator,
  firstNameValidator,
  generateDays,
  generateMonths,
  generateYears,
  lastNameValidator
} from "../../../util/validator";
import {Label} from "../../register/validation/4-validation";
import {PhoneErrors} from "../myAccountTypes";
import {
  cancelChanges, convertDob,
  displayCancelButton,
  handleChangeEdit,
  handleDobMonthChange,
  initialPageLoad,
  updatePhoneNumbers,
  validateEditableFields
} from './helpers/helpers';
import {useAtom, useSetAtom} from 'jotai';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  AutocompleteValue,
  Button,
  createFilterOptions,
  FormHelperText,
  FormLabel,
  InputLabel,
  Link,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import {phoneAtom, profileInfoAtom} from '../../../jotai-state-management/profile-global-state';
import OneTimeNameEditDialog from "./one-time-name-change-dialogue/oneTimeNameEditDialogue";
import {PhoneNumbers, TextFieldProps} from './profile';
import {removeDashesPhoneNumber} from '../../../util/phoneFormatter';
import {t} from 'i18next';
import {useNavigate} from 'react-router-dom';
import {GlobalContext} from '../../../contexts/global';
import {useSnackbar} from 'notistack';
import { getContactUsLink } from '../../../util/helpers';

export type NameFields = {
  firstName: string,
  firstNameInitialState: string,
  firstNameHelperText: string
  lastName: string,
  lastNameInitialState: string,
  lastNameHelperText: string
}

export type DOBFields = {
  dobYear: string,
  dobMonth: string,
  dobDay: string,
  dobHelperText: string,
}

export const ProfileTextFields = (props: TextFieldProps): JSX.Element => {
  const {theme} = props;
  const navigate = useNavigate();
  const {enqueueSnackbar} = useSnackbar();
  const {setGlobalContext} = useContext(GlobalContext);
  const [phone] = useAtom<PhoneNumbers>(phoneAtom);
  const updatePhone = useSetAtom(phoneAtom);
  const [profileInfo, updateProfileInfo] = useAtom<ProfileInfoType>(profileInfoAtom);
  const [loadingPhoneNumberChange, setLoadingPhoneNumberChange] = useState<boolean>(false);
  const [showCancelButton, setShowCancelButton] = useState<boolean>(false);
  const [initialLoad, setInitialLoad] = useState<boolean>(false);
  const [disableNameFields, setDisableNameFields] = useState<boolean>(false);
  const [phoneEditState, setPhoneEditState] = useState<PhoneNumbers>({
    primary: '',
    alternate1: '',
    alternate2: ''
  });
  const [phoneEditInitialState, setPhoneEditInitialState] = useState<PhoneNumbers>({
    primary: '',
    alternate1: '',
    alternate2: ''
  });
  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState<PhoneNumbers>({
    primary: '',
    alternate1: '',
    alternate2: ''
  });
  const [phoneEditErrors, setPhoneEditErrors] = useState<PhoneErrors>({
    primaryIncorrectlyEntered: {
      value: null,
      disableSaveButton: false
    },
    alternate1PhoneIncorrectlyEntered: {
      value: null,
      disableSaveButton: false
    },
    alternate2PhoneIncorrectlyEntered: {
      value: null,
      disableSaveButton: false
    },
    primaryTooShort: {
      value: null,
      disableSaveButton: false
    },
    primaryEmpty: {
      value: null,
      disableSaveButton: false
    }
  });
  const [nameFields, setNameFields] = useState<NameFields>({
    firstName: '',
    firstNameInitialState: '',
    firstNameHelperText: '',
    lastName: '',
    lastNameInitialState: '',
    lastNameHelperText: ''
  })
  const [dobFields, setDobFields] = useState<DOBFields>({
    dobYear: profileInfo.dateOfBirth?.year || '',
    dobMonth: profileInfo.dateOfBirth?.month || '',
    dobDay: profileInfo.dateOfBirth?.day || '',
    dobHelperText: '',
  });
  const [openOneTimeEditDialogue, setOpenOneTimeEditDialogue] = useState<boolean>(false);
  const selectYears: Label[] = useMemo(() => generateYears(), []);
  const selectMonths: Label[] = useMemo(() => generateMonths(), []);
  const selectDays: Label[] = useMemo(() => generateDays(), []);
  const [disableDOBFields, setDisableDOBFields] = useState<boolean>(false);

  useEffect(() => {
    initialPageLoad(
      setInitialLoad,
      setPhoneEditInitialState,
      setPhoneEditState,
      profileInfo,
      phone,
      setNameFields,
      setDisableNameFields,
      setDisableDOBFields
    );
  }, [undefined, phone]);

  useEffect(() => {
    displayCancelButton(
      phoneEditState,
      phoneEditInitialState,
      setShowCancelButton,
      nameFields,
      dobFields,
      profileInfo
    );
  });

  // validate DOB on profile page when this is 1 time editable
  useEffect(() => {
    const validateDOB = async () => {
      const convertedDOB: string = convertDob(dobFields);
      // only validate the DOB when it is loaded in the users profile and is editable
      if (dobValidator(convertedDOB, t).length !== 0 && (!disableDOBFields && initialLoad)) {
        setDobFields((prev: DOBFields) => ({
          ...prev,
          dobHelperText: dobValidator(convertedDOB, t)
        }));
      }
    }
    validateDOB();
  }, [initialLoad, dobFields.dobYear, dobFields.dobMonth, dobFields.dobDay])

  useEffect(() => {
    if (initialLoad) {
      validateEditableFields(phoneEditState, setPhoneEditErrors, 'profile');
    }
    updatePhoneNumbers(setFormattedPhoneNumber, phoneEditState);
  }, [phoneEditState]);

  // Cleaner and easier to read. Calculate validators for profile fields - these are calculated on every render
  const nameMatch: boolean = nameFields.firstName === nameFields.firstNameInitialState &&
    nameFields.lastName === nameFields.lastNameInitialState;
  const phoneMatch: boolean = phoneEditInitialState.primary === phoneEditState.primary &&
    phoneEditInitialState.alternate1 === phoneEditState.alternate1 &&
    phoneEditInitialState.alternate2 === phoneEditState.alternate2;
  const primaryError: boolean = phoneEditErrors.primaryIncorrectlyEntered.disableSaveButton ||
    phoneEditErrors.primaryEmpty.disableSaveButton;
  const alternate1Error: boolean = phoneEditErrors.alternate1PhoneIncorrectlyEntered.disableSaveButton;
  const alternate2Error: boolean = phoneEditErrors.alternate2PhoneIncorrectlyEntered.disableSaveButton;
  const dobFieldsEmpty: boolean = Boolean(!dobFields.dobDay || !dobFields.dobMonth || !dobFields.dobYear);
  const helperText: string = nameFields.firstNameHelperText || nameFields.lastNameHelperText;
  const dobFieldsHelperError: boolean = Boolean(dobFields.dobHelperText);

  const shouldDisable = () => {
    if (dobFieldsHelperError) {
      return true;
    }
    if ((!disableNameFields && !disableDOBFields) && (phoneMatch && dobFieldsEmpty && nameMatch)) {
      return true;
    }
    if ((disableNameFields && !disableDOBFields) && (phoneMatch && dobFieldsEmpty)) {
      return true;
    }
    if ((!disableNameFields && disableDOBFields) && (nameMatch && phoneMatch)) {
      return true;
    }
    if ((disableNameFields && disableDOBFields) && phoneMatch) {
      return true;
    }
    if (primaryError || alternate1Error || alternate2Error || helperText) {
      return true;
    }
    return false;
  }

  const shouldDisableSaveButton: boolean = useMemo(() => {
    return Boolean(shouldDisable())
  }, [
    nameFields.firstName,
    nameFields.firstNameInitialState,
    nameFields.lastName,
    nameFields.lastNameInitialState,
    phoneEditInitialState.primary,
    phoneEditState.primary,
    phoneEditInitialState.alternate1,
    phoneEditState.alternate1,
    phoneEditInitialState.alternate2,
    phoneEditState.alternate2,
    phoneEditErrors.primaryIncorrectlyEntered.disableSaveButton,
    phoneEditErrors.primaryEmpty.disableSaveButton,
    phoneEditErrors.alternate1PhoneIncorrectlyEntered.disableSaveButton,
    phoneEditErrors.alternate2PhoneIncorrectlyEntered.disableSaveButton,
    dobFields.dobDay,
    dobFields.dobMonth,
    dobFields.dobYear,
    dobFields.dobHelperText,
    disableNameFields,
    disableDOBFields,
    helperText
  ]);

  // @ts-ignore
  return (
    <div className="innerColumn">
      <OneTimeNameEditDialog
        setOpenOneTimeEditDialogue={setOpenOneTimeEditDialogue}
        openOneTimeEditDialogue={openOneTimeEditDialogue}
        setLoadingPhoneNumberChange={setLoadingPhoneNumberChange}
        setPhoneEditState={setPhoneEditState}
        setPhoneEditInitialState={setPhoneEditInitialState}
        updateProfileInfo={updateProfileInfo}
        phone={phone}
        updatePhone={updatePhone}
        phoneEditState={phoneEditState}
        navigate={navigate}
        setGlobalContext={setGlobalContext}
        enqueueSnackbar={enqueueSnackbar}
        nameFields={nameFields}
        setNameFields={setNameFields}
        theme={theme}
        dobFields={dobFields}
        nameMatch={nameMatch}
        setDisableDOBFields={setDisableDOBFields}
      />
      <Grid
        container rowSpacing={2}
        columnSpacing={{md: 5, lg: 5}}
      >
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <p className="italic noMargin">To protect your account, the information highlighted in grey below cannot be
            modified. If you need to correct or update it please&nbsp;
            <Link
              href={getContactUsLink(theme)}
              target="_blank"
              className="link-blue"
            >contact us
            </Link>.
          </p>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <InputLabel className="inputLabel">{t('FIRST NAME')}*</InputLabel>
          <TextField
            disabled={disableNameFields}
            id="outlined-basic"
            variant="outlined"
            // @ts-ignore
            error={nameFields.firstNameHelperText}
            helperText={nameFields.firstNameHelperText}
            value={nameFields.firstName}
            className="fullWidth"
            inputProps={{'aria-label': 'First name'}}
            onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
              setNameFields((prev: NameFields) => ({
                ...prev,
                firstName: event.target.value,
                firstNameHelperText: firstNameValidator(trimDoubleSpace(event.target.value), t)
              }));
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <InputLabel className="inputLabel">{t('LAST NAME')}*</InputLabel>
          <TextField
            disabled={disableNameFields}
            id="outlined-basic"
            variant="outlined"
            // @ts-ignore
            error={nameFields.lastNameHelperText}
            helperText={nameFields.lastNameHelperText}
            value={nameFields.lastName}
            className="fullWidth"
            inputProps={{'aria-label': 'Last name'}}
            onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
              setNameFields((prev: NameFields) => ({
                ...prev,
                lastName: event.target.value,
                lastNameHelperText: lastNameValidator(trimDoubleSpace(event.target.value), t)
              }));
            }}
          />
        </Grid>
        {!disableNameFields &&
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <p className="italic noMargin">You will be able to edit your name one time only.</p>
          </Grid>
        }
        <Grid className="FormLabelWrapper" item xs={12} sm={12} md={12} lg={12}>
          <FormLabel required className="FormLabel">PHONE</FormLabel>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <InputLabel className="inputLabel">{t('Primary')}*</InputLabel>
          <TextField
            id="outlined-basic"
            variant="outlined"
            className="fullWidth"
            type="tel"
            autoComplete="tel"
            inputProps={{
              maxLength: 12,
              'aria-label': 'Primary Phone'
            }}
            helperText={phoneEditErrors.primaryIncorrectlyEntered.value || phoneEditErrors.primaryEmpty.value}
            // @ts-ignore
            error={phoneEditErrors.primaryIncorrectlyEntered.value || phoneEditErrors.primaryEmpty.value}
            FormHelperTextProps={{style: {color: '#e50176', position: 'absolute', marginTop: '60px'}}}
            value={formattedPhoneNumber.primary}
            onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
              setPhoneEditState((prev: PhoneNumbers) => ({
                ...prev,
                primary: removeDashesPhoneNumber(event.target.value)
              }));
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <InputLabel className="inputLabel">{t('Alternate 1')}</InputLabel>
          <TextField
            id="outlined-basic"
            variant="outlined"
            className="fullWidth"
            type="tel"
            inputProps={{
              maxLength: 12,
              'aria-label': 'Alternate Phone'
            }}
            helperText={phoneEditErrors.alternate1PhoneIncorrectlyEntered.value}
            // @ts-ignore
            error={phoneEditErrors.alternate1PhoneIncorrectlyEntered.value}
            FormHelperTextProps={{style: {color: '#e50176', position: 'absolute', marginTop: '60px'}}}
            value={formattedPhoneNumber.alternate1}
            onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
              setPhoneEditState((prev: PhoneNumbers) => ({
                ...prev,
                alternate1: removeDashesPhoneNumber(event.target.value)
              }));
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <InputLabel className="inputLabel">{t('Alternate 2')}</InputLabel>
          <TextField
            id="outlined-basic"
            variant="outlined"
            className="fullWidth"
            type="tel"
            inputProps={{
              maxLength: 12,
              'aria-label': 'Another Alternate Phone'
            }}
            helperText={phoneEditErrors.alternate2PhoneIncorrectlyEntered.value}
            // @ts-ignore
            error={phoneEditErrors.alternate2PhoneIncorrectlyEntered.value}
            FormHelperTextProps={{style: {color: '#e50176', position: 'absolute', marginTop: '60px'}}}
            value={formattedPhoneNumber.alternate2}
            onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
              setPhoneEditState((prev: PhoneNumbers) => ({
                ...prev,
                alternate2: removeDashesPhoneNumber(event.target.value)
              }));
            }}
          />
        </Grid>
        <Grid className="FormLabelWrapper" item xs={12} sm={12} md={12} lg={12}>
          <FormLabel required className="FormLabel">DATE OF BIRTH</FormLabel>
        </Grid>
        {disableDOBFields ?
          <>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <InputLabel required className="inputLabel">Year</InputLabel>
              <TextField
                id="outlined-basic"
                disabled
                // label="Home Phone"
                variant="outlined"
                className="fullWidth"
                inputProps={{
                  maxLength: 12,
                  'aria-label': 'Year'
                }}
                // @ts-ignore
                FormHelperTextProps={{style: {color: '#e50176', position: 'absolute', marginTop: '60px'}}}
                value={dobFields.dobYear}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <InputLabel required className="inputLabel">Month</InputLabel>
              <TextField
                id="outlined-basic"
                disabled
                // label="Home Phone"
                variant="outlined"
                className="fullWidth"
                inputProps={{
                  maxLength: 12,
                  'aria-label': 'Month'
                }}
                // @ts-ignore
                FormHelperTextProps={{style: {color: '#e50176', position: 'absolute', marginTop: '60px'}}}
                value={dobFields.dobMonth}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <InputLabel required className="inputLabel">Day</InputLabel>
              <TextField
                id="outlined-basic"
                disabled
                // label="Home Phone"
                variant="outlined"
                className="fullWidth"
                inputProps={{
                  maxLength: 12,
                  'aria-label': 'Day'
                }}
                // @ts-ignore
                FormHelperTextProps={{style: {color: '#e50176', position: 'absolute', marginTop: '60px'}}}
                value={dobFields.dobDay}
              />
            </Grid>
          </>
          :
          <>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <InputLabel required className="inputLabel">Year</InputLabel>
              <Autocomplete
                className="fullWidth"
                disablePortal
                options={selectYears}
                value={dobFields.dobYear}
                autoHighlight
                autoSelect
                isOptionEqualToValue={(option, value) => {
                  if (typeof value === 'string') {
                    return option.value === value;
                  }
                  return option.value == value.value;
                }}
                onChange={(event: SyntheticEvent, select: AutocompleteValue<any, any, any, any>) => {
                  if (select) {
                    setDobFields((prev: DOBFields) => ({
                      ...prev,
                      dobYear: select.value,
                      dobHelperText: ''
                    }));
                  } else {
                    setDobFields((prev: DOBFields) => ({
                      ...prev,
                      dobYear: null,
                      dobHelperText: ''
                    }));
                  }
                }}
                renderInput={(params: AutocompleteRenderInputParams) =>
                  <TextField
                    error={dobFields.dobHelperText !== ''}
                    {...params}
                  />
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <InputLabel required className="inputLabel">Month</InputLabel>
              <Autocomplete
                className="fullWidth"
                disablePortal
                autoHighlight
                autoSelect
                options={selectMonths}
                value={dobFields.dobMonth}
                isOptionEqualToValue={(option, value) => {
                  if (typeof value === 'string') {
                    return option.value === value;
                  }
                  return option.value == value.value;
                }}
                filterOptions={createFilterOptions({
                  matchFrom: 'any',
                  stringify: (option) => option.value + ' ' + option.label
                })}
                onChange={(event: SyntheticEvent, select: AutocompleteValue<any, any, any, any>) => {
                  handleDobMonthChange(select, setDobFields);
                }}
                renderInput={(params: AutocompleteRenderInputParams) =>
                  <TextField
                    error={dobFields.dobHelperText !== ''}
                    {...params}
                  />
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <InputLabel required className="inputLabel">Day</InputLabel>
              <Autocomplete
                className="fullWidth"
                disablePortal
                autoHighlight
                autoSelect
                options={selectDays}
                value={dobFields.dobDay}
                isOptionEqualToValue={(option, value) => {
                  if (typeof value === 'string') {
                    return option.value === value;
                  }
                  return option.value == value.value;
                }}
                onChange={(event: SyntheticEvent, select: AutocompleteValue<any, any, any, any>) => {
                  if (select) {
                    setDobFields((prev: DOBFields) => ({
                      ...prev,
                      dobDay: select.value,
                      dobHelperText: ''
                    }));
                  } else {
                    setDobFields((prev: DOBFields) => ({
                      ...prev,
                      dobDay: null,
                      dobHelperText: ''
                    }));
                  }
                }}
                filterOptions={createFilterOptions({
                  matchFrom: 'any',
                  stringify: (option) => option.value + ' ' + option.label
                })}
                renderInput={(params: AutocompleteRenderInputParams) =>
                  <TextField
                    error={dobFields.dobHelperText !== ''}
                    {...params}
                  />
                }
              />
            </Grid>
            {dobFields.dobHelperText !== '' &&
              <FormHelperText className={'dobHelperProfile'}>{dobFields.dobHelperText}</FormHelperText>
            }
            {(!dobFields.dobYear || !dobFields.dobDay || !dobFields.dobMonth) &&
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <p className="italic noMargin warningMessage">You must add a date of birth to save changes to your
                  profile.</p>
              </Grid>
            }
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <p className="italic noMargin">You will be able to edit your Date of birth one time only.</p>
            </Grid>
          </>
        }
      </Grid>
      <div className="phoneButton">
        {loadingPhoneNumberChange
          ?
          <LoadingButton
            loading
            className="primaryButtonDefault"
            loadingPosition="start"
            sx={{mt: 3, mb: 2}}
            startIcon={<SaveIcon/>}
            variant="outlined"
          >
            Saving...
          </LoadingButton>
          :
          <>
            {showCancelButton &&
              <Button
                sx={{mt: 3, mb: 2}}
                className="cancelButtonDefault"
                onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => cancelChanges(
                  event,
                  setPhoneEditState,
                  phoneEditInitialState,
                  nameFields,
                  setNameFields,
                  setDobFields,
                  profileInfo
                )}
              >Cancel
              </Button>
            }
            <Button
              // @ts-ignore
              disabled={shouldDisableSaveButton}
              type="submit"
              className="primaryButtonDefault"
              variant="contained"
              sx={{mt: 3, mb: 2}}
              onClick={() => handleChangeEdit(
                setLoadingPhoneNumberChange,
                setPhoneEditState,
                setPhoneEditInitialState,
                updateProfileInfo,
                phone,
                updatePhone,
                phoneEditState,
                profileInfo,
                navigate,
                setGlobalContext,
                enqueueSnackbar,
                nameFields,
                setNameFields,
                setOpenOneTimeEditDialogue,
                false,
                theme,
                dobFields,
                setDisableDOBFields
              )}
            >
              Save
            </Button>
          </>
        }
      </div>
      <Grid
        container rowSpacing={2}
        columnSpacing={{xs: 20, sm: 20, md: 30}}
      >
      </Grid>
    </div>
  );
};