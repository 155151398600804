import React, {useState} from 'react';
import {appleWallet, appleWalletWithJwt, googlePay, googlePayWithJwt} from "../../network-requests/resources";
import {pushSpecificEvent} from "../../util/google-tag-manager";
import './add-wallet-buttons.css';
import AppleWalletImage from '../../images/apple-wallet-button-two-line-large_2x.png';
import GoogleWalletImage from '../../images/enCA_add_to_google_wallet_add-wallet-badge.png';


interface WalletButtonProps {
    card: string; // The More Rewards Card string
    digitalMRJwt: string;
}

const WalletButton = (props: WalletButtonProps) => {
    // Extract the 'card' value from the props.
    const { card, digitalMRJwt } = props;

    // Determine if Apple Pay should be displayed based on the user-agent.
    const [isApplePaySupported] = useState(
        !(navigator.userAgent.indexOf('CriOS') !== -1 || navigator.userAgent.indexOf('Chrome') !== -1) &&
        navigator.userAgent.indexOf('Safari') !== -1
    );

    // Determine if Google Pay should be displayed based on the user-agent.
    const [isGooglePaySupported] = useState(
        navigator.userAgent.indexOf('CriOS') !== -1 || navigator.userAgent.indexOf('Chrome') !== -1
    );

    const handleGoogleWalletClick = async () => {
        try {
            let wallet: Response = await googlePayWithJwt(card, digitalMRJwt);
            if (wallet.status != 200) {
                console.error('Error in generating google pay', wallet.text());
                return;
            }
            let jwt: string = await wallet.json();
            window.open(`https://pay.google.com/gp/v/save/${jwt}`, '_blank');
            pushSpecificEvent('Add to Google wallet');
        } catch (e) {
            console.error('Error Downloading Google Wallet', e);
        }
    };

    const handleAppleWalletClick = async () => {
        try {
            let wallet: Response = await appleWalletWithJwt(card, digitalMRJwt);
            if (wallet.status !== 200) {
                console.error('Error in downloading apple wallet', wallet.text());
                return;
            }
            let walletJson: any = await wallet.json();
            let element: HTMLAnchorElement = document.createElement('a');
            element.setAttribute(
                'href',
                'data:application/vnd.apple.pkpass;base64,' +
                encodeURIComponent(walletJson.appleFile)
            );
            element.setAttribute('download', 'apple-wallet.pkpass');
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
            pushSpecificEvent('Add to Apple wallet');
        } catch (e) {
            console.error('Error Downloading Apple Wallet', e);
        }
    };

    return (
        <div className="centeredContainer">
            <div className="walletButtons">
                {/* Render Apple Pay button if Apple Pay is supported */}
                {isApplePaySupported && (
                    <a onClick={handleAppleWalletClick} className="addToPayButton">
                        <img
                            className="addToPayImage"
                            src={AppleWalletImage}
                            alt="Add to Apple Wallet"
                            height={55}
                        />
                    </a>
                )}

                {/* Render Google Pay button if Google Pay is supported */}
                {isGooglePaySupported && (
                    <a onClick={handleGoogleWalletClick} className="addToPayButton">
                        <img
                            className="addToPayImage"
                            src={GoogleWalletImage}
                            alt="Add to Google Wallet"
                        />
                    </a>
                )}
            </div>
        </div>
    );
};

export default WalletButton;
