import React from 'react';
import {GlobalContextType} from '../../contexts/types/global';
import {FRUser} from '@forgerock/javascript-sdk';
import {NavigateFunction} from 'react-router';
import {setDataAnalMetaData} from '../../util/google-tag-manager';
import {
  AM_URL,
  LINK_MR,
  RELAY_STATE,
  RELAY_STATE_CHOICES,
  RELAY_STATE_MEINHARDT,
  RELAY_STATE_PSF,
  RELAY_STATE_SOF,
  RELAY_STATE_UF,
  RELAY_STATE_REACT_APP_MR
} from "../../util/constants";

// logic to work with ForgeRock SDK to log out the user also has safety check to always ensure iPlanet cookie is deleted on logout
export const logoutOauthSession = async (
  setGlobalContext: React.Dispatch<React.SetStateAction<GlobalContextType>>,
  navigate: NavigateFunction,
  setIsAuth: any,
  type: string,
  goto: string | null
): Promise<void> => {
  try {
    await FRUser.logout();
    await setGlobalContext((prev: any) => ({
      ...prev,
      SSOAuthenticated: false,
      sessionTimedOut: false
    }));
    setDataAnalMetaData({ prid: undefined });
    if (goto && goto === '/register') {
      return navigate('/register')
    }
    if (type !== 'logoutButton') {
      navigate('/');
    } else {
      window.location.replace(`${LINK_MR}`);
    }
    setTimeout(() => {
      setIsAuth(null); // delay this by 1.5 seconds to improve animation transition to drupal page
    }, 1500);
  } catch (error) {
    console.error(`Error with logout; ${error}`);
  }
};

// Sign out of all Service Providers and punt user back to login page to login again for the correct banner
export const breakSSOChoicesAccount = () => {
  return window.location.replace(
    `${AM_URL}/IDPSloInit?metaAlias=/customers/morerewards-api/idp&RelayState=${RELAY_STATE_REACT_APP_MR}`
  )
}

/* single sign out for all service providers.
 * initiate HTTP redirect to kick off SSO of all SP's. punt user to correct banner on logout
 * https://developer.mozilla.org/en-US/docs/Web/API/Window/location
 * https://stackoverflow.com/questions/13106950/set-window-location-with-typescript */
export const signOutAllSPs = async (
  goto: string | null,
  theme: string
): Promise<void> => {
  try {
    // revoke access token
    await FRUser.logout();
    if (goto) { // replace relayState with goto to handle mobile web view
      return window.location.replace(`${AM_URL}/IDPSloInit?metaAlias=/customers/morerewards-api/idp&goto=${goto}`);
    }
    switch (theme) {
      case 'choices':
        return window.location.replace(
          `${AM_URL}/IDPSloInit?metaAlias=/customers/morerewards-api/idp&RelayState=${RELAY_STATE_CHOICES}`
        );
      case 'meinhardt':
        return window.location.replace(
          `${AM_URL}/IDPSloInit?metaAlias=/customers/morerewards-api/idp&RelayState=${RELAY_STATE_MEINHARDT}`
        );
      case 'saveonfoods':
        return window.location.replace(
          `${AM_URL}/IDPSloInit?metaAlias=/customers/morerewards-api/idp&RelayState=${RELAY_STATE_SOF}`
        );
      case 'pricesmartfoods':
        return window.location.replace(
          `${AM_URL}/IDPSloInit?metaAlias=/customers/morerewards-api/idp&RelayState=${RELAY_STATE_PSF}`
        );
      case 'urbanfare':
        return window.location.replace(
          `${AM_URL}/IDPSloInit?metaAlias=/customers/morerewards-api/idp&RelayState=${RELAY_STATE_UF}`
        );
      default:
        return window.location.replace(
          `${AM_URL}/IDPSloInit?metaAlias=/customers/morerewards-api/idp&RelayState=${RELAY_STATE}`
        );
    }
  } catch (error) {
    console.error(`Error with logout; ${error}`);
  }
};