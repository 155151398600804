import {OAuth2Tokens} from "@forgerock/javascript-sdk";
import React from 'react';
import {GlobalContextType} from "../../contexts/types/global";
import {startRefreshTokens, validateiPlanetSession} from "../../network-requests/authentication";
import {getContextUserInfo} from '../../network-requests/resources';
import {NavigateFunction} from 'react-router';
import {
  LINK_CHOICES,
  LINK_MEINHARDT,
  LINK_MR,
  LINK_PSF,
  LINK_SOF, LINK_TRAVEL,
  LINK_UF,
  LINK_QF,
  LINK_SOF_IOS_APP,
  LINK_SOF_ANDROID_APP,
  LINK_UF_IOS_APP,
  LINK_UF_ANDROID_APP,
  LINK_PSF_IOS_APP,
  LINK_PSF_ANDROID_APP,
  LINK_CHOICES_IOS_APP,
  LINK_CHOICES_ANDROID_APP
} from "../../util/constants";
import {PhoneNumbers} from "./profile/profile";

const setUserInContext = async (
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setError: React.Dispatch<React.SetStateAction<string>>,
  updatePhone: any,
  updateProfile: any
) => {
  setError(null);
  setLoading(true);
  let myAccountData: any = await getContextUserInfo(setError);
  if (myAccountData) {
    updateProfile((prev: any) => ({
      ...prev,
      email: myAccountData.email,
      firstName: myAccountData.firstName,
      lastName: myAccountData.lastName,
      province: myAccountData.province,
      homeStore: myAccountData.homeStore,
      moreRewardsCard: myAccountData.moreRewardsCard,
      points: myAccountData.points,
      dateOfBirth: myAccountData.dateOfBirth,
      emailSubscriptions: myAccountData.emailSubscriptions,
      addresses: myAccountData.addresses,
      linkedAccounts: myAccountData.linkedAccounts,
      googleJwt: myAccountData.googleJwt,
      nameChangeAttributes: myAccountData.nameChangeAttributes,
      dobString: myAccountData.dobString,
      biometricData: myAccountData.biometricData
    }));
    updatePhone((prev: PhoneNumbers) => ({
      ...prev,
      primary: myAccountData.primary,
      alternate1: myAccountData.alternate1,
      alternate2: myAccountData.alternate2,
    }));
  }
  setLoading(false);
};

const handleSelectedTab = function (
  selectedTab: number,
  navigate: NavigateFunction,
  setSelectedTab: React.Dispatch<React.SetStateAction<number>>
) {
  switch (selectedTab) {
    case 0:
      navigate('/userPage/home');
      setSelectedTab(0);
      break;
    case 1:
      navigate('/userPage/manageAddresses');
      setSelectedTab(1);
      break;
    case 2:
      navigate('/userPage/changeEmail');
      setSelectedTab(2);
      break;
    case 3:
      navigate('/userPage/changePassword');
      setSelectedTab(3);
      break;
    case 4:
      navigate('/userPage/emailSubscriptions');
      setSelectedTab(4);
      break;
    case 5:
      navigate('/userPage/household');
      setSelectedTab(5);
      break;
    case 6:
      navigate('/userPage/card');
      setSelectedTab(6);
      break;
    case 7:
      navigate('/userPage/deleteAccount');
      setSelectedTab(7);
      break;
  }
}

const handleDrupalRouting = (
  navigate: NavigateFunction,
  setSelectedTab: React.Dispatch<React.SetStateAction<number>>,
) => {
  let pathname = location.pathname;
  switch (pathname) {
    case '/userPage/manageAddresses':
      setSelectedTab(1);
      navigate('/userPage/manageAddresses');
      break;
    case '/userPage/changeEmail':
      setSelectedTab(2);
      navigate('/userPage/changeEmail');
      break;
    case '/userPage/changePassword':
      setSelectedTab(3);
      navigate('/userPage/changePassword');
      break;
    case '/userPage/emailSubscriptions':
      setSelectedTab(4);
      navigate('/userPage/emailSubscriptions');
      break;
    case '/userPage/household':
      setSelectedTab(5);
      navigate('/userPage/household');
      break;
    case '/userPage/card':
      setSelectedTab(6);
      navigate('/userPage/card');
      break;
    case '/userPage/deleteAccount':
      setSelectedTab(7);
      navigate('/userPage/deleteAccount');
      break;
    default:
      navigate('/userPage/home');
  }
};

const validateProfileOnMount = async (
  setSelectedTab: React.Dispatch<React.SetStateAction<number>>,
  setError: React.Dispatch<React.SetStateAction<string>>,
  setGlobalContext: React.Dispatch<React.SetStateAction<GlobalContextType>>,
  globalContext: GlobalContextType,
  navigate: NavigateFunction,
  setErrorLogin: React.Dispatch<React.SetStateAction<string>>,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  isAuth: OAuth2Tokens | void,
  setIsAuth: any,
  enqueueSnackbar: any,
  goto: string,
  updatePhone: any,
  updateProfile: any,
  rehydrateApplication: boolean,
  setRehydrateApplication: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const sessionValid = await validateiPlanetSession( // 1 - first thing we check is if the session is valid or not
    setGlobalContext,
    navigate,
    globalContext,
    setIsAuth,
    isAuth,
    setErrorLogin,
    setLoading,
    enqueueSnackbar,
    goto
  );
  if (sessionValid) { // then check if session is valid to load their profile, if not don't do anything else here
    await startRefreshTokens(); // 2.
    await handleDrupalRouting(navigate, setSelectedTab); // 3.
    await setUserInContext(setLoading, setError, updatePhone, updateProfile); // 4. fetching the users profile with valid access token
    if (rehydrateApplication) {
      setRehydrateApplication(false);
    }
  }
}

const handleChangeBackButton = (
  setSelectedTab: React.Dispatch<React.SetStateAction<number>>,
  navigate: NavigateFunction,
): void => {
  let pathname = location.pathname;
  switch (pathname) {
    case '/userPage/home':
      setSelectedTab(0);
      break;
    case '/userPage/manageAddresses':
      setSelectedTab(1);
      break;
    case '/userPage/changeEmail':
      setSelectedTab(2);
      break;
    case '/userPage/changePassword':
      setSelectedTab(3);
      break;
    case '/userPage/emailSubscriptions':
      setSelectedTab(4);
      break;
    case '/userPage/household':
      setSelectedTab(5);
      break;
    case '/userPage/card':
      setSelectedTab(6);
      break;
    case '/userPage/deleteAccount':
      setSelectedTab(7);
      break;
    // catch when user hits back button too many times and punt them to home page
    case '/userPage':
      navigate('/userPage/home');
      setSelectedTab(0);
      break;
  }
};

// handle redirection for buttons in app to banners based on theme
const handleThemeRedirection = async (theme: string, trafficType: any) => {
  switch (theme) {
    case 'choices':
      if (trafficType === 'app' || sessionStorage.getItem('trafficType') === 'app') {
        return handleRedirectApp(theme);
      } else {
        return window.location.replace(`${LINK_CHOICES}`);
      }
    case 'saveonfoods':
      if (trafficType === 'app' || sessionStorage.getItem('trafficType') === 'app') {
        return handleRedirectApp(theme);
      } else {
        return window.location.replace(`${LINK_SOF}`);
      }
    case 'pricesmartfoods':
      if (trafficType === 'app' || sessionStorage.getItem('trafficType') === 'app') {
        return handleRedirectApp(theme);
      } else {
        return window.location.replace(`${LINK_PSF}`);
      }
    case 'urbanfare':
      if (trafficType === 'app' || sessionStorage.getItem('trafficType') === 'app') {
        return handleRedirectApp(theme);
      } else {
        return window.location.replace(`${LINK_UF}`);
      }
    case 'meinhardt':
      return window.location.replace(`${LINK_MEINHARDT}`);
    case 'qualityfoods':
      return window.location.replace(`${LINK_QF}`);
    case 'travel':
      return window.location.replace(`${LINK_TRAVEL}`)
    default:
      return window.location.replace(`${LINK_MR}`);
  }
}

function handleRedirectApp(theme: string) {
  if (theme === 'saveonfoods') {
    window.location.replace(`${LINK_SOF_IOS_APP}`);
    setTimeout(function () {
      window.location.replace(`${LINK_SOF_ANDROID_APP}`);
    }, 200);
  } else if (theme === 'urbanfare') {
    window.location.replace(`${LINK_UF_IOS_APP}`);
    setTimeout(function () {
      window.location.replace(`${LINK_UF_ANDROID_APP}`);
    }, 200);
  } else if (theme === 'pricesmartfoods') {
    window.location.replace(`${LINK_PSF_IOS_APP}`);
    setTimeout(function () {
      window.location.replace(`${LINK_PSF_ANDROID_APP}`);
    }, 200);
  } else if (theme === 'choices') {
    window.location.replace(`${LINK_CHOICES_IOS_APP}`);
    setTimeout(function () {
      window.location.replace(`${LINK_CHOICES_ANDROID_APP}`);
    }, 200);
  }
}

export {
  setUserInContext,
  handleSelectedTab,
  handleDrupalRouting,
  handleChangeBackButton,
  validateProfileOnMount,
  handleThemeRedirection
}
