import React from 'react';
import {CircularProgress} from "@mui/material";

export type MyCircularProgressProps = {
  theme: string; // Update 'default' to the actual default theme value
}


// Single circular loading component to be used throughout the entire project when needed.
export const CircularLoading: React.FC<MyCircularProgressProps> = ({ theme }): JSX.Element => {
  return (
    <div className="loadingContainer">
      <CircularProgress
        style={{
          minWidth: '135px',
          minHeight: '135px',
          marginTop: '150px',
          marginBottom: '250px',
          color: theme === 'choices' ? '#444444' : 'white'
        }}
      />
    </div>
  );
}
