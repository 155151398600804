import {Link, Typography} from "@mui/material";
import { getContactUsLink } from '../../../util/helpers';
import React from "react";

export const displayCustomError = (
  enqueueSnackbar: any,
  theme: string,
  msg: string
): any => {
  return enqueueSnackbar(
    <>
      <Typography
        sx={{ marginRight: '5px', marginLeft: '5px', cursor: 'pointer' }}>
        {msg}. If you require assistance please&nbsp;
        <Link
          href={getContactUsLink(theme)}
          target="_blank"
          className="link-white"
        >contact us
        </Link>
      </Typography>
    </>,
    {
      variant: 'error',
      autoHideDuration: 5000
    }
  )
}