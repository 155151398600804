import React, {useState} from 'react';
import Typography from '@mui/material/Typography';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import './manage-device-biometrics.scss';
import {Switch, CircularProgress } from "@mui/material";
import {deregisterDevice} from "../../network-requests/resources";
import {
  FRAuth,
  FRLoginFailure,
  FRLoginSuccess,
  FRStep,
  FRWebAuthn,
  WebAuthnStepType
} from "@forgerock/javascript-sdk";
import {
  INCORRECT_WEB_AUTH_STEP_TYPE
} from "../../util/constants";
import { getTermsAndConditionsLink } from "../../util/helpers";
import {verifyStepTypeValid, verifyTheWebAuthnStepType} from "../../network-requests/authentication";
import {GlobalContextType} from "../../contexts/types/global";
import Link from "@mui/material/Link";
import {pushSpecificEvent} from "../../util/google-tag-manager";
import {useAtom} from "jotai";
import {profileInfoAtom} from "../../jotai-state-management/profile-global-state";

type DeviceInfoProps = {
  profileInfo: any,
  globalContext: GlobalContextType,
  enqueueSnackbar: (message: string, options?: any) => void,
};

const DeviceInfo: React.FC<DeviceInfoProps> = ({ profileInfo, globalContext, enqueueSnackbar }) => {
  if (profileInfo.biometricData === null) {
    profileInfo.biometricData = {
      deviceName: ''
    }
  }
  const [, updateProfileInfo] = useAtom(profileInfoAtom);
  const [deviceName, setDeviceName] = useState<any>(profileInfo.biometricData.deviceName);
  const [loading, setLoading] = useState<boolean>(false);
  const termsLink = getTermsAndConditionsLink(globalContext.referer);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    if (deviceName !== null && deviceName !== '') {
      pushSpecificEvent('Disabling biometric login');
      // Deregister device
      deregisterDevice(enqueueSnackbar).then((r: Response) => {
        if (r.status === 200) {
          setDeviceName(null);
        }
        // disable remember me if biometric is disabled
        localStorage.removeItem('userLastSignedInWithBiometric');
      }).finally(() => {
        setLoading(false);
        updateProfileInfo((prev: any) => ({ ...prev, biometricData: {
            deviceName: null
          }}));
      });
    } else {
      pushSpecificEvent('Enabling biometric login');
      // Register device
      registerDevice().then(() => {
        setDeviceName('new device');
        return localStorage.setItem('userLastSignedInWithBiometric', profileInfo.email);
      }).finally(() => {
        setLoading(false);
        updateProfileInfo((prev: any) => ({ ...prev, biometricData: {
            deviceName: 'new device'
          }}));
      });
    }
  }

  const registerDevice = async (): Promise<any> => {
    const configOverrides = { tree: 'MRIDBioRegister' };
    let step: FRStep | FRLoginSuccess | FRLoginFailure = await FRAuth.start(configOverrides);
    try {
      if (!verifyStepTypeValid(step)) throw Error('Invalid next step type');
      const stepType: WebAuthnStepType = await verifyTheWebAuthnStepType(step);

      if (stepType === WebAuthnStepType.Registration) {
        step = await FRWebAuthn.register(step);
      } else if (stepType === WebAuthnStepType.None) {
        throw INCORRECT_WEB_AUTH_STEP_TYPE;
      }
      await FRAuth.next(step, configOverrides);
      return step;
    } catch (e: any) {
      console.error(e);
      throw e;
    }
  }

  return (
    <div className="deviceIcon">
      <div className="deviceFlexbox">
        <FingerprintIcon style={{ fontSize: '65px', marginRight: '7px' }} />
        {
          deviceName !== null && deviceName !== '' ?
            <div className="deviceInformation">
              <div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography
                    variant="body1"
                    className="biometricsFont"
                  >
                    {globalContext.isIos?"Face ID / Touch ID is enabled":"Biometric Login is enabled."}<br/><br/>
                  </Typography>
                </div>
              </div>
              {
                loading?
                  <CircularProgress />
                  :
                  <Switch
                    checked={deviceName !== null && deviceName !== ''}
                    onChange={handleChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
              }
            </div>
            :
            <div className="deviceInformation">
              <div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography
                    variant="body1"
                    className="biometricsFont"
                  >
                    When enabled you can use your fingerprint or Face ID on this device. <Link href={termsLink} target="_blank">Terms of Use</Link>
                  </Typography>
                </div>
              </div>
              {
                loading?
                  <CircularProgress />
                  :
                  <Switch
                    checked={deviceName !== null && deviceName !== ''}
                    onChange={handleChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
              }
            </div>
        }
      </div>
    </div>
  );
};

export default DeviceInfo;