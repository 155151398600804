import {Link, Typography} from "@mui/material";
import { getContactUsLink } from '../../../util/helpers';
import React from "react";

export const displayAccountNoActiveCardError = (enqueueSnackbar: any, theme: string): any => {
  return enqueueSnackbar(
    <>
      <Typography
        sx={{ marginRight: '5px', marginLeft: '5px', cursor: 'pointer' }}>
        This account has been disabled. For assistance please&nbsp;
        <Link
          href={getContactUsLink(theme)}
          target="_blank"
          className="link-white"
        >contact us
        </Link>
      </Typography>
    </>,
    { variant: 'error' }
  )
}