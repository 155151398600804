import React, {useContext, useEffect, useMemo, useState} from 'react';
import CardWithLogo from "../../components/card/card-with-logo";
import {Checkboxes} from '../../contexts/types/myAccount';
import {GlobalContext} from '../../contexts/global';
import {Route, Routes, useNavigate, useSearchParams} from 'react-router-dom';
import {logoutOauthSession} from "../login/logout";
import {generateCheckboxDivs} from './email-subscriptions/generate-checkbox-divs/generateCheckboxDivs';
import {useAtom, useSetAtom} from 'jotai';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  handleChangeBackButton, handleThemeRedirection,
  handleSelectedTab,
  validateProfileOnMount
} from './myAccountHelpers';
import {Button, Card, Drawer, List, ListItem, Typography} from '@mui/material';
import {
  LINK_CONTACT_US,
  LINK_MR_DONE
} from '../../util/constants';
import {phoneAtom, profileInfoAtom} from '../../jotai-state-management/profile-global-state';
import {useSnackbar} from 'notistack';
import {Profile} from './profile/profile';
import {
  handleCheckboxChange,
  setInitialCheckBoxState,
  verifyAnyCheckboxesAreSelected
} from './email-subscriptions/helper-functions/helpers';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PageFooter from '../../components/footer/footer';
import Addresses from './addresses/addresses';
import ChangeEmail from './change-email/changeEmail';
import ChangePassword from './change-password/changePassword';
import EmailSubscriptions from './email-subscriptions/emailSubscriptions';
import LinkedAccounts from './linked-accounts/linkedAccounts';
import MoreRewardsCard from './card/morerewardscard';
import IdeTimerContainer from '../../components/idle-timer/idleTimerContainer';
import DeleteAccount from './delete-account/deleteAccount';
import Partners from "../../components/partners/partners";
import {CircularLoading} from "../../components/loading/circular-loading";
import CloseIcon from '@mui/icons-material/Close';
import PersonIcon from '@mui/icons-material/Person';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import LockIcon from '@mui/icons-material/Lock';
import LinkIcon from '@mui/icons-material/Link';
import EmailIcon from '@mui/icons-material/Email';
// @ts-ignore
import {Helmet} from 'react-helmet';
import {MyAccountPagesProps} from '../../App';
import {CheckboxState} from './email-subscriptions/emailSubscriptionsTypes';
import './myAccount.scss';

export const checkboxObject: Checkboxes = {
  moreRewardsProgram: false,
  saveOnFoodsFlyerSpecials: false,
  specialOffersMealPrep: false,
  urbanFareSpecials: false,
  priceSmartSpecials: false,
  qualityFoodsSpecials: false,
  surveys: false
};

// Hamburger options for all MR themes
const hamburgerOptions: string[] = [
  'Profile',
  'Addresses',
  'Email',
  'Security Settings',
  'Email Subscriptions',
  'Linked Cards',
  'My Card'
];

// Hamburger options for all MR themes
const hamburgerOptionsWebview: string[] = [
  'Profile',
  'Addresses',
  'Email',
  'Security Settings',
  'Email Subscriptions',
  'Linked Cards'
];

type Anchor = 'top' | 'left' | 'bottom' | 'right';

// Blacklisted hamburger options for choices theme
const mrHamburgerOptions: string[] = [
  'Email Subscriptions',
  'Linked Cards',
  'My Card'
];

// Renders when an access token is present in local storage, regardless of the iPlanetDirectoryPro cookie.
export default function MyAccountPage(props: MyAccountPagesProps) {
  const [searchParams] = useSearchParams();
  const forceAuth: boolean = (useMemo(() => searchParams.get('forceAuth'), []) === 'true');
  const navigate = useNavigate();
  const {
    isAuth,
    rehydrateApplication,
    setRehydrateApplication,
    setIsAuth,
    setErrorLogin,
    goto,
    theme
  } = props;
  const {enqueueSnackbar} = useSnackbar();
  const {globalContext, setGlobalContext} = useContext(GlobalContext);
  const updatePhone = useSetAtom(phoneAtom);
  const [profileInfo] = useAtom(profileInfoAtom);
  const [, updateProfile] = useAtom(profileInfoAtom);
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [openDrawer, setOpenDrawer] = useState<any>({top: false});
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [apiIsLoading, setApiIsLoading] = useState<boolean>(false);
  const [checkboxDivs, setCheckboxDivs] = useState<JSX.Element[]>([]);
  const [checkboxState, setCheckboxState] = useState<CheckboxState>({
    initialCheckboxesState: checkboxObject,
    initialCheckboxesSnapshot: checkboxObject,
    checkboxSnapshotsMatch: true,
    anyCheckboxesAreSelected: false
  });

  // Detect when back button is clicked and select the right tab
  useEffect(() => {
    window.onpopstate = () => {
      handleChangeBackButton(setSelectedTab, navigate);
    };
  });

  // Break SSO for a previously logged in choices account
  useEffect(() => {
    breakSSO();
  }, [theme, forceAuth]);

  /* force all of these steps when the profile mounts to happen
   * synchronously when the component mounts one after the other */
  useEffect(() => {
    validateProfileOnMount(
      setSelectedTab,
      setError,
      setGlobalContext,
      globalContext,
      navigate,
      setErrorLogin,
      setLoading,
      isAuth,
      setIsAuth,
      enqueueSnackbar,
      goto,
      updatePhone,
      updateProfile,
      rehydrateApplication,
      setRehydrateApplication
    );
  }, [undefined, rehydrateApplication, updateProfile]);

  // Load the checkboxes under the email subs page
  useEffect(() => {
    if (profileInfo.emailSubscriptions) {
      setInitialCheckBoxState(profileInfo, setCheckboxState); // 5.
    }
  }, [profileInfo]);

  useEffect(() => {
    generateCheckboxDivs(
      setCheckboxDivs,
      handleCheckboxChange,
      checkboxState,
      setCheckboxState,
      loading,
      apiIsLoading
    );
    verifyAnyCheckboxesAreSelected(setCheckboxState, checkboxState);
  }, [checkboxState.initialCheckboxesState, loading, apiIsLoading]);

  const HandleChangeTabMenu = (event: React.SyntheticEvent, value: number): void => {
    handleSelectedTab(value, navigate, setSelectedTab);
  };

  /* Breaks SSO for a previously logged in Choices account attempting to log into a different banner.
   * Reads a 'forceAuth' URL param from ForgeRock AM upon redirection into the React app from an E-comm Banner site
   * or it falls back to checking 'spEntityIdFromLogin' in local storage (url param fails for whatever reason) */
  const breakSSO = async () => {
    try {
      const bannersToBreakSSO: string[] = ["saveonfoods", "pricesmartfoods", "urbanfare", "morerewards"]; // MRID banners to break SSO
      const spEntityIdFromLogin: string = localStorage.getItem('spEntityId'); // We're looking for a choices spEntityIdFromLogin here
      if (bannersToBreakSSO.includes(theme) && (forceAuth || spEntityIdFromLogin === 'choicesmarkets')) {
        await localStorage.removeItem('spEntityId');
        await logoutOauthSession(
          setGlobalContext,
          navigate,
          setIsAuth,
          'breakSSOChoices',
          null
        ); // Just use ForgeRock AM SDK to revoke the access token and iPlanetDirectoryProCookie
      }
    } catch (e) {
      console.error(e);
    }
  }

  const toggleDrawer = (
    anchor: Anchor,
    open: boolean,
    openDrawer: any,
    setOpenDrawer: React.Dispatch<any>
  ) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setOpenDrawer({...openDrawer, [anchor]: open});
  };

  const list = (anchor: Anchor) => (
    <Box
      sx={{width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250}}
      role="presentation"
      className="HamburgerNav"
      onClick={toggleDrawer(anchor, false, openDrawer, setOpenDrawer)}
      onKeyDown={toggleDrawer(anchor, false, openDrawer, setOpenDrawer)}
    >
      {globalContext.isWebview && globalContext.referer === 'morerewards' ?
        <List>
          {hamburgerOptionsWebview.map((text: string, index: number) => (
            ( theme === "choices" || theme === "meinhardt" ) && mrHamburgerOptions.includes(text)
              ?
              <></>
              :
              <>
                <ListItem key={index} disablePadding>
                  <Tab
                    label={text}
                    className="listItemHamburger"
                    onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => HandleChangeTabMenu(event, index)}
                  />
                </ListItem>
              </>
          ))}
        </List>
        :
        <List>
          {hamburgerOptions.map((text: string, index: number) => (
            ( theme === "choices" || theme === "meinhardt" ) && mrHamburgerOptions.includes(text)
              ?
              <></>
              :
              <>
                <ListItem key={index} disablePadding>
                  <Tab
                    label={text}
                    className="listItemHamburger"
                    onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => HandleChangeTabMenu(event, index)}
                  />
                </ListItem>
              </>
          ))}
        </List>
      }
      <CloseIcon className="closeIcon" onClick={toggleDrawer(anchor, false, openDrawer, setOpenDrawer)}/>
    </Box>
  );

  return (
    <>
      <div className="page accountWrapper">
        <CardWithLogo children={null} displayBannerLogo={!globalContext.isWebview} globalContext={globalContext}/>
        {error
          ?
          <Typography className={theme === "choices" ? "errorTopHeaderChoices marginB40" : "errorTopHeader marginB40"}>
            {error}. If you have any trouble, please&nbsp;
            <a href={LINK_CONTACT_US} target="_blank" className={
              theme === "choices" ? "link-choices underline" : "link-white underline"
            }
            >contact us</a>.
          </Typography>
          :
          <>
            {globalContext.isWebview ?
              <>
                {theme === 'choices' ?
                  <div className="mobileTooltip">To return to your mobile app use the navigation button in the top
                    left of your screen. If the navigation is missing please swipe back or close and re-open the
                    app.</div>
                  :
                  <div className="mobileTooltip">To return to your mobile app use the navigation button in the top
                    left of your screen. If the navigation is missing please swipe back or close and re-open the
                    app.</div>
                }
              </>
              :
              <Typography variant="h2" component="h2" className="topHeader">Account Settings</Typography>
            }
            <div className="mainWrapper">
              {loading
                ?
                <div className="loadingProfile">
                  <Box>
                    <CircularLoading theme={theme}/>
                  </Box>
                </div>
                :
                <>
                  {globalContext.isWebview ?
                    <div/>
                    :
                    <div className="account-header">
                      <Button className="backButtonDefault" onClick={() => handleThemeRedirection(theme, globalContext.trafficType)}><ArrowBackIcon className={'backIcon'}/>Back</Button>
                    </div>
                  }
                  <Card className="wide-white-box">
                    <div className="accountflex">
                      {(() => {
                        switch (selectedTab) {
                          case 0:
                            return <PersonIcon className="icon"/>;
                          case 1:
                            return <HomeWorkIcon className="icon"/>;
                          case 2:
                            return <EmailIcon className="icon"/>;
                          case 3:
                            return <LockIcon className="icon"/>;
                          case 4:
                            return <EmailIcon className="icon"/>;
                          case 5:
                            return <LinkIcon className="icon"/>;
                          case 6:
                            return <CreditCardIcon className="icon"/>;
                          case 7:
                            return;
                        }
                      })()}
                      {selectedTab !== 7
                        ?
                        <Tabs
                          value={selectedTab}
                          onChange={HandleChangeTabMenu}
                          aria-label="Tab Menu"
                          className="accountNav-container"
                        >
                          <Tab disabled={loading} label="Profile" className="tabsDesktop"/>
                          <Tab disabled={loading} label="Addresses" className="tabsDesktop"/>
                          <Tab disabled={loading} label="Email" className="tabsDesktop"/>
                          <Tab disabled={loading} label="Security Settings" className="tabsDesktop"/>
                          {globalContext.referer !== 'choices' && theme !== 'meinhardt' &&
                            <Tab disabled={loading} label="Email Subscriptions" className="tabsDesktop"/>}
                          {globalContext.referer !== 'choices' && theme !== 'meinhardt' &&
                            <Tab disabled={loading} label="Linked Cards" className="tabsDesktop"/>}
                          {globalContext.isWebview || ( globalContext.referer !== 'choices' && theme !== 'meinhardt' ) &&
                            <Tab disabled={loading} label="My Card" className="tabsDesktop"/>}
                          <React.Fragment key={'top'}>
                            <div onClick={toggleDrawer('top', true, openDrawer, setOpenDrawer)} className="mobileAccountNav">
                              <img aria-label="Hamburger Menu" alt="Hamburger Menu" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCA1MCA1MCI+CjxwYXRoIGQ9Ik0gMCA3LjUgTCAwIDEyLjUgTCA1MCAxMi41IEwgNTAgNy41IFogTSAwIDIyLjUgTCAwIDI3LjUgTCA1MCAyNy41IEwgNTAgMjIuNSBaIE0gMCAzNy41IEwgMCA0Mi41IEwgNTAgNDIuNSBMIDUwIDM3LjUgWiI+PC9wYXRoPgo8L3N2Zz4="/>
                            </div>
                            <Drawer
                              anchor={'top'}
                              open={openDrawer['top']}
                              onClose={toggleDrawer('top', false, openDrawer, setOpenDrawer)}
                            >
                              {list('top')}
                            </Drawer>
                          </React.Fragment>
                        </Tabs>
                        :
                        <div/>
                      }
                    </div>
                    <div className="account-container">
                      <Routes>
                        <Route path="/home" element={
                          <>
                            {theme === 'choices' ?
                              <Helmet><title>Profile | Choices Markets</title></Helmet>
                              :
                              <Helmet><title>Profile | More Rewards ID</title></Helmet>
                            }
                            <Profile theme={theme}/>
                          </>
                        }>
                        </Route>
                        );
                        <Route path="/manageAddresses" element={
                          <>
                            {theme === 'choices' ?
                              <Helmet><title>Edit Address | Choices Markets</title></Helmet>
                              :
                              <Helmet><title>Edit Address | More Rewards ID</title></Helmet>
                            }
                            <Addresses loading={loading} theme={theme} isWebview={globalContext.isWebview}/>
                          </>
                        }>
                        </Route>
                        <Route path="/changeEmail" element={
                          <>
                            {theme === 'choices' ?
                              <Helmet><title>Change Email | Choices Markets</title></Helmet>
                              :
                              <Helmet><title>Change Email | More Rewards ID</title></Helmet>
                            }
                            <ChangeEmail setRehydrateApplication={setRehydrateApplication} theme={theme}/>
                          </>
                        }>
                        </Route>
                        <Route path="/changePassword" element={
                          <>
                            {theme === 'choices' ?
                              <Helmet><title>Security Settings | Choices Markets</title></Helmet>
                              :
                              <Helmet><title>Security Settings | More Rewards ID</title></Helmet>
                            }
                            <ChangePassword theme={theme}/>
                          </>
                        }>
                        </Route>
                        <Route path="/emailSubscriptions" element={
                          <>
                            <Helmet><title>Email Subscriptions | More Rewards ID</title></Helmet>
                            <EmailSubscriptions
                              loading={loading}
                              checkboxDivs={checkboxDivs}
                              setCheckboxDivs={setCheckboxDivs}
                              checkboxState={checkboxState}
                              setCheckboxState={setCheckboxState}
                              apiIsLoading={apiIsLoading}
                              setApiIsLoading={setApiIsLoading}
                              profilePage={false}
                              theme={theme}
                              setSelectedTab={setSelectedTab}
                            />
                          </>
                        }>
                        </Route>
                        <Route path="/household" element={
                          <>
                            <Helmet><title>Linked Cards | More Rewards ID</title></Helmet>
                            <LinkedAccounts
                              loading={loading}
                              theme={theme}
                              setSelectedTab={setSelectedTab}
                            />
                          </>
                        }>
                        </Route>
                        {globalContext.isWebview && globalContext.referer === 'morerewards' ?
                          <></>
                          :
                          <Route path="/card" element={
                            <>
                              <Helmet><title>Card | More Rewards ID</title></Helmet>
                              <MoreRewardsCard
                                loading={loading}
                                theme={theme}
                                setSelectedTab={setSelectedTab}
                              />
                            </>
                          }>
                          </Route>
                        }
                        <Route path="/deleteAccount" element={
                          <>
                            {theme === 'choices' ?
                              <Helmet><title>Delete Account | Choices Markets</title></Helmet>
                              :
                              <Helmet><title>Delete Account | More Rewards ID</title></Helmet>
                            }
                            <DeleteAccount loading={loading} setSelectedTab={setSelectedTab} theme={theme}/>
                          </>
                        }>
                        </Route>
                      </Routes>
                    </div>
                  </Card>
                </>
              }
              {globalContext.isWebview ?
                <>
                  {theme === 'choices' ?
                    <div className="mobileTooltipBottom">To return to your mobile app use the navigation button in the top
                      left of your screen. If the navigation is missing please swipe back or close and re-open the
                      app.</div>
                    :
                    <div className="mobileTooltipBottom">To return to your mobile app use the navigation button in the top
                      left of your screen. If the navigation is missing please swipe back or close and re-open the
                      app.</div>
                  }
                </>
                :
                <div className="account-footer">
                  <Button className="backButtonDefault" onClick={() => handleThemeRedirection(theme, globalContext.trafficType)}><ArrowBackIcon className={'backIcon'}/>Back</Button>
                </div>
              }
            </div>
          </>
        }
      </div>
      <IdeTimerContainer
        setGlobalContext={setGlobalContext}
        setIsAuth={setIsAuth}
        theme={theme}
        goto={goto}
      />
      <div className="flexCenter" onClick={() => {
        window.location.replace(`${LINK_MR_DONE}`);
      }}>
      </div>
      {globalContext.isWebview ?
        <></>
        :
        <>
          {globalContext.referer !== 'choices'
            ?
            <Partners/>
            :
            <></>
          }
          <PageFooter isLogin={false} theme={theme}/>
        </>
      }
    </>
  );
}