import React, {createContext, Dispatch, SetStateAction, useState} from 'react';

export type RegInfoType = {
  currentPage: RegistrationPagesEnum,
  moreRewardsCard: string,
  moreRewardsActivationCode: string,
  needMrCard: boolean,
  email: string,
  fName: string,
  lName: string,
  password: string,
  addressLine1: string,
  addressLine2: string,
  city: string,
  postalCode: string,
  country: string,
  province: string,
  mobilePhone: string,
  homePhone: string,
  workPhone: string,
  dob: string,
  sendEmails: boolean,
  jwt: string,
  submit: boolean,
  publicReference: number,
  existingAccount: boolean,
  wallet: {
    googleJWT: string,
    appleFile: string
  }
  theme: string,
  actionId?: number
}

export enum RegistrationPagesEnum {
  'None',
  'VerifyEmailPage',
  'CreateAccountPage',
  'SecurityQuestionsPage',
  'ContactCustomerServicePage',
  'ValidationPage',
  'AddressPage',
  'VerifyEmailExpiredPage',
  'ChangePasswordPage',
  'RegisterCompletePage',
  'DigitalRegistrationPage',
  'DigitalRegisterCompletePage'
}

type RegInfoContextType = {
  registrationContext: RegInfoType,
  setRegistrationContext: Dispatch<SetStateAction<RegInfoType>>
}

export const RegInfoContextDefaultValue: RegInfoType = {
  currentPage: RegistrationPagesEnum.None,
  moreRewardsCard: '',
  moreRewardsActivationCode: '',
  needMrCard: false,
  email: '',
  fName: '',
  lName: '',
  password: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  postalCode: '',
  country: '',
  province: '',
  mobilePhone: '',
  homePhone: '',
  workPhone: '',
  dob: '',
  sendEmails: false,
  jwt: '',
  submit: false,
  publicReference: 0,
  existingAccount: false,
  wallet: {
    googleJWT: null,
    appleFile: null
  },
  theme: ''
};
/* ignoring because type will be set below
 * problem is https://betterprogramming.pub/how-to-use-react-context-with-typescript-the-easy-way-2ed1010f6e84
 * the default value can't be set before the provider making type undefined or RegInfoContextType and the
 * context needs to be init before the provider
 * @ts-ignore */
const RegistrationContext = createContext<RegInfoContextType>(undefined);
const RegistrationContextProvider = (props: { children: any, regInfoInit?: RegInfoType }) => {
  let regInfoInitial;
  if (props.regInfoInit) {
    regInfoInitial = props.regInfoInit;
  }
  else {
    regInfoInitial = RegInfoContextDefaultValue;
  }
  const [registrationContext, setRegistrationContext] = useState<RegInfoType>(regInfoInitial);
  return (
    <RegistrationContext.Provider
      value={{registrationContext: registrationContext, setRegistrationContext: setRegistrationContext}}>
      {props.children}
    </RegistrationContext.Provider>
  );
};

export default RegistrationContextProvider;
export {RegistrationContext};

